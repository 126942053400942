import React, { useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import SpLoadingOverlay from "../../../../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import { sleep } from "../../../../../utils/utils";
import TransactionPin from "../../../../../ui/TransactionPin/TransactionPin";
import { updateTransactionPin } from "../../../../../redux/actions/account";

const ChangePin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const formData = location.state || {};
  const [pin, setPin] = useState("");

  const transactionPinState = useSelector(state => state.account.txPin);
  const { loading: isLoading } = transactionPinState?? {};

  const handleSubmit = useCallback(() => {
    const payload = { ...formData, new_pin: pin };

    dispatch(updateTransactionPin(payload))
      .then((response) => {
        message.success(response?.message);
        
        sleep(500).then(() => {
          navigate('/account', {replace: true});
        });
      })
      .catch(error => {
        message.error(error);
        setPin("");
      });

  }, [dispatch, formData, pin]);

  const onPinSuccess = useCallback((pin) => setPin(pin), []);

  const onComplete = useCallback(
    (newPin) => {
      if (pin !== newPin) {
        message.error("Previous PIN does not match Confirm PIN");
        return;
      }

      handleSubmit();
    },
    [pin]
  );

  return (
    <SpLoadingOverlay isLoading={isLoading} style={{padding: 0}}>
      <div className="auth">
        <div style={{textAlign: 'center'}}>
          <h3>{pin.length < 4 ? "Set Transaction PIN" : "Confirm Transaction PIN"}</h3>
          <p>This would be used to authorize wallet transactions</p>
        </div>

        {pin.length < 4 && (
            <div className="form">
              <div>
                <TransactionPin onComplete={onPinSuccess} />
              </div>
            </div>
          )}

          {pin.length === 4 && (
            <div className="form">
              <div id="slide">
                <TransactionPin onComplete={onComplete} />
              </div>
            </div>
          )}
      </div>
    </SpLoadingOverlay>
  );
}

export default ChangePin;

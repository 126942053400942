import { useDispatch, useSelector } from "react-redux";
import withUserData from "../../../../../HOC/withUserData";
import SpButton from "../../../../../sp-components/SpButton/SpButton";
import { sendOtp } from "../../../../../redux/actions/otpVerification";
import { NAV_LOGIN, NAV_UPDATE_TRANSACTION_PIN_VERIFY } from './../../../../../routes/routes.constant';
import { redirectAfterDelay } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import { message } from "antd";


const UpdateTransactionPin = ({userData, loggedInAsGuest}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const otpSendState = useSelector(state => state.otpVerification.send);

    const { loading: isLoading } = otpSendState;

    const initialFormData = {
        otp_meta: {
            channel: "SMS",
            action: "RESET_TRANSACTION_PIN"
          },
    };

    const handleSendOtp = () => {

        if (loggedInAsGuest) {
            redirectAfterDelay(NAV_LOGIN);
        }

        const payload = {
            ...initialFormData.otp_meta,
            ['recipient']: userData?.phoneNumber,
        }

        dispatch(sendOtp(payload)).then((response) => {
            message.success(response?.message);

            const formData = {
                ...initialFormData,
                ['phone_number']: userData?.phoneNumber,
            }
       
            navigate(NAV_UPDATE_TRANSACTION_PIN_VERIFY, {state: formData});
          }).catch(error => console.log("Otp error"));
    }

    return (<>
        <p>To be able to reset your transaction PIN, you will need to verify with a one time password (OTP) sent to your phone number.</p>
        <SpButton loading={isLoading} style={{maxWidth: 159, height: 52}} onClick={handleSendOtp}>Verify OTP</SpButton>
    </>);
}

export default withUserData(UpdateTransactionPin);
const cableIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M7.52444 5.75601L7.52501 5.75687H5.85834C3.77501 5.75687 2.08334 7.44854 2.08334 9.5319V14.0819C2.08334 16.1569 3.77501 17.8486 5.85834 17.8486H14.1417C16.225 17.8486 17.9167 16.1569 17.9167 14.0819V9.5319C17.9167 7.44854 16.225 5.75687 14.1417 5.75687H9.59168L9.59226 5.75601H7.52444Z"
      fill={fill}
    />
    <path
      d="M9.59234 5.75605L11.375 3.13208C11.575 2.84041 11.5 2.45708 11.2167 2.25708C10.925 2.06541 10.5417 2.14041 10.3417 2.42375L8.55834 5.04875L6.82498 2.43208C6.63332 2.14875 6.24165 2.06541 5.95832 2.25708C5.66665 2.44875 5.59165 2.83208 5.78332 3.12375L7.5243 5.75605H9.59234Z"
      fill={fill}
    />
    <path
      d="M10.0667 16.4655C11.675 16.4655 12.725 15.3572 12.725 13.7572V9.84883C12.725 8.24883 11.675 7.14883 10.0667 7.14883H6.17497C4.56663 7.14883 3.51663 8.24883 3.51663 9.84883V13.7572C3.51663 15.3572 4.56663 16.4655 6.17497 16.4655H10.0667Z"
      fill={fill}
    />
  </svg>
);

export default cableIcon;

import React from "react";
import { CTA, Faqs, Features, Hero, ProvidersSection, QuestionSection } from "./AboutUs.helper";
import ScrollToTopOnMount from "../../components/ScrollToTop/ScrollToTop";

const AboutUs = () => {
  return (
    <>
    <ScrollToTopOnMount />
    <div className="aboutUs">
     <Hero />
     <QuestionSection />
     <Features />
     <ProvidersSection />
     <Faqs />
     <CTA />
    </div>
    </>
  );
};

export default AboutUs;

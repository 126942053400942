import React from "react";

const SendMoney = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.381166"
        y="0.380859"
        width="29.8426"
        height="29.8426"
        rx="8.95278"
        fill="#9747FF"
      />
      <path
        d="M22.3359 8.27227C21.9629 7.89103 21.4108 7.75002 20.896 7.89924L8.89184 11.3677C8.3487 11.5184 7.96374 11.9489 7.86003 12.495C7.75409 13.0516 8.12414 13.7588 8.60759 14.0543L12.361 16.3462C12.746 16.5819 13.2429 16.523 13.5615 16.2037L17.8595 11.9056C18.0759 11.681 18.434 11.681 18.6504 11.9056C18.8667 12.1212 18.8667 12.4726 18.6504 12.6964L14.3448 16.9945C14.0255 17.3138 13.9658 17.8092 14.2008 18.1949L16.4942 21.9626C16.7628 22.4095 17.2254 22.6639 17.7327 22.6639C17.7924 22.6639 17.8595 22.6639 17.9192 22.6557C18.5012 22.5818 18.9637 22.1856 19.1353 21.6261L22.694 9.71217C22.8507 9.20485 22.709 8.65276 22.3359 8.27227Z"
        fill="#FEFEFE"
      />
      <path
        opacity="0.4"
        d="M13.4011 20.6301C13.6189 20.8487 13.6189 21.203 13.4011 21.4216L12.3819 22.44C12.273 22.5497 12.1298 22.6042 11.9865 22.6042C11.8433 22.6042 11.7 22.5497 11.5911 22.44C11.3725 22.2214 11.3725 21.8678 11.5911 21.6492L12.6095 20.6301C12.8281 20.4122 13.1825 20.4122 13.4011 20.6301ZM12.8164 17.804C13.0342 18.0226 13.0342 18.377 12.8164 18.5955L11.7972 19.6139C11.6883 19.7236 11.5451 19.7781 11.4018 19.7781C11.2586 19.7781 11.1153 19.7236 11.0064 19.6139C10.7878 19.3953 10.7878 19.0417 11.0064 18.8231L12.0248 17.804C12.2434 17.5861 12.5978 17.5861 12.8164 17.804ZM10.0103 16.9144C10.2281 17.133 10.2281 17.4873 10.0103 17.7059L8.99114 18.7243C8.88222 18.834 8.73897 18.8885 8.59573 18.8885C8.45248 18.8885 8.30924 18.834 8.20031 18.7243C7.98171 18.5057 7.98171 18.1521 8.20031 17.9335L9.21869 16.9144C9.43729 16.6965 9.79167 16.6965 10.0103 16.9144Z"
        fill="#FEFEFE"
      />
    </svg>
  );
};
export default SendMoney;

import apiClient from "../api/apiClient";

export const fetchUserRequest = (data) =>
  apiClient({
    method: "PUT",
    url: "/user/edit/profile",
    data,
  });

export const updateUserRequest = (data) =>
  apiClient({
    method: "PUT",
    url: "/user/edit/profile",
    data,
  });

export const logoutRequest = (data) =>
  apiClient({
    method: "POST",
    url: "/auth/logout",
    data,
  });

export const setPINRequest = (payload) =>
  apiClient({
    method: "POST",
    url: "/user/transactionPin",
    data: payload,
  });


export const getBeneficiaryTransactions = (utilityType) =>
  apiClient({
    method: "GET",
    url: "/user/beneficiary",
    params: {
      utilityType: utilityType,
    },
  });

export const editTransactionPin = (data) =>
  apiClient({
    method: "PUT",
    url: "/user/edit-transaction-pin",
    data,
  });

export const reserveVirtualAccount = (data) =>
  apiClient({
    method: "POST",
    url: "/user/reserve-virtual-account",
    data,
  });

export const verifyNIN = (data) =>
  apiClient({
    method: "POST",
    url: "/user/kyc/card",
    data,
  });

export const verifyAddress = (data) =>
  apiClient({
    method: "POST",
    url: "/user/kyc/address",
    data,
  });

export const getKycLevel = () =>
  apiClient({
    method: "GET",
    url: "/user/kyc",
  });

export const sendVerifyBvnOTP = (data) =>
  apiClient({
    method: "POST",
    url: "/user/send-otp",
    data,
  });

export const verifyBvnOTP = (data) =>
  apiClient({
    method: "POST",
    url: "/user/verify-otp",
    data,
  });

export const getAccountDetails = () =>
  apiClient({
    method: "GET",
    url: "/user/account-details",
  });

export const getUserTransactions = (data) =>
  apiClient({
    method: "POST",
    url: `/transactions`,
  });

export const getAllRecurringPayments = () =>
  apiClient({
    method: "GET",
    url: "/user/recurring-payment",
  });

export const editRecurringPayment = (id, requestBody) =>
  apiClient({
    method: "PUT",
    url: `/user/recurring-payment/${id}`,
    data: requestBody,
  });

export const deleteRecurringPayment = (id) =>
  apiClient({
    method: "DELETE",
    url: `/user/recurring-payment/${id}`,
  });

export const createRecurringPayment = (data) =>
  apiClient({
    method: "POST",
    url: "/user/recurring-payment",
    data,
  });

export const getAllBeneficiaries = (data) =>
  apiClient({
    method: "GET",
    url: `/user/beneficiary?disco=${data}`,
  });

export const addBeneficiary = (data) =>
  apiClient({
    method: "POST",
    url: "/user/beneficiary",
    data,
  });

export const getUserBeneficiaryTransactions = () =>
  apiClient({
    method: "POST",
    url: `/account/beneficiaries`,
  });

export const editBeneficiary = (id, requestBody) =>
  apiClient({
    method: "PUT",
    url: `/user/beneficiary/${id}`,
    data: requestBody,
  });

export const deleteBeneficiary = (id) =>
  apiClient({
    method: "DELETE",
    url: `/user/beneficiary/${id}`,
  });

// export const getUserTransactions = async (utilityType) => {
//   try {
//     const response = await instance.get("/user/transactions", {
//       params: { utilityType },
//     });
//     console.log(response);
//     return response.data.data.message.itemsList;
//   } catch (error) {
//     throw new Error(getApiErrorMessage(error));
//   }
// };

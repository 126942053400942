import payWithCard from "../../assets/svgs/pay-with-card.svg";
import payWithWallet from "../../assets/svgs/pay-with-wallet.svg";
import memoizeOne from "memoize-one";
import Spinner from "../../ui/Spinner/Spinner";
import { isStaging } from "../../helpers/server";

export const TRANSACTION_STATUS = {
  SUCCESSFUL: "successful",
  COMPLETED: "completed",
};

export const PAYMENT_STATUS = [
  TRANSACTION_STATUS.SUCCESSFUL,
  TRANSACTION_STATUS.COMPLETED,
];

export const SP_ACCOUNT_TYPES = {
  PREPAID: "prepaid",
  POSTPAID: "postpaid",
};

export const SP_PAYMENT_TYPES = {
  CARD: "card",
  WALLET: "wallet",
};

export const FW_PAYMENT_OPTIONS = "card, banktransfer, ussd";
export const FW_REDIRECT_URL = `${window.location.origin}/electricity`;
export const FW_SAPA_LOGO =
  "https://nftstorage.link/ipfs/bafybeieyvoegaqtwxhg4hiozkuionxlczao2m2xc7zywukchmiddqlt6mu/sapa%20logo.png";

const SERVICES = {
  AEDC: "AEDC",
  EEDC: "EEDC",
  EKEDC: "EKEDC",
  IKEDC: "IKEDC",
  ABA_POWER: "APLE",
  PHEDC: "PHEDC",
  IBEDC: "IBEDC",
  KEDCO: "KEDCO",
  JEDC: "JEDC",
  BEDC: "BEDC",
  KAEDC: "KAEDC",
};

export const SERVICE_TO_EXCLUDE = [""];

export const SERVICE_MAP = {
  [SERVICES.AEDC]: {
    key: "aedc",
    logo: require("../../assets/logo/services/AEDC.png"),
    type: "electricity",
  },
  [SERVICES.EEDC]: {
    key: "eedc",
    logo: require("../../assets/logo/services/EEDC.png"),
    type: "electricity",
  },
  [SERVICES.EKEDC]: {
    key: "ekedc",
    logo: require("../../assets/logo/services/EKEDC.png"),
    type: "electricity",
  },
  [SERVICES.IKEDC]: {
    key: "ikeja",
    logo: require("../../assets/logo/services/IKEJA.png"),
    type: "electricity",
  },
  [SERVICES.ABA_POWER]: {
    key: "aple",
    logo: require("../../assets/logo/services/ABA-POWER.png"),
    type: "electricity",
  },
  [SERVICES.PHEDC]: {
    key: "phedc",
    logo: require("../../assets/logo/services/PHEDC.png"),
    type: "electricity",
  },
  [SERVICES.IBEDC]: {
    key: "ibedc",
    logo: require("../../assets/logo/services/IBEDC.png"),
    type: "electricity",
  },
  [SERVICES.KEDCO]: {
    key: "kedco",
    logo: require("../../assets/logo/services/Kaduna Electric.png"),
    type: "electricity",
  },
  [SERVICES.JEDC]: {
    key: "kaedc",
    logo: require("../../assets/logo/services/JOSDC.png"),
    type: "electricity",
  },
  [SERVICES.BEDC]: {
    key: "bedc",
    logo: require("../../assets/logo/services/Benin Electric.png"),
    type: "electricity",
  },
  [SERVICES.KAEDC]: {
    key: "kaedc",
    logo: require("../../assets/logo/services/KEDCO.png"),
    type: "electricity",
  },
};

export const SERVICE_MAP_OPTIONS = [
  {
    key: "aedc",
    label: "AEDC",
    logo: require("../../assets/logo/services/AEDC.png"),
    type: "electricity",
  },
  {
    key: "eedc",
    label: "EEDC",
    logo: require("../../assets/logo/services/EEDC.png"),
    type: "electricity",
  },
  {
    key: "ekedc",
    label: "EKEDC",
    logo: require("../../assets/logo/services/EKEDC.png"),
    type: "electricity",
  },
  {
    key: "ikeja",
    label: "IKEJA",
    logo: require("../../assets/logo/services/IKEJA.png"),
    type: "electricity",
  },
  {
    key: "aba_power",
    label: "ABA POWER",
    logo: require("../../assets/logo/services/ABA POWER.png"),
    type: "electricity",
  },
  {
    key: "phedc",
    label: "PHEDC",
    logo: require("../../assets/logo/services/PHEDC.png"),
    type: "electricity",
  },
];

export const ACCOUNT_TYPES_OPTIONS = [
  { value: "prepaid", label: "Prepaid" },
  { value: "postpaid", label: "Postpaid" },
  // { value: "disabled", label: "Disabled", disabled: true },
];

export const AMOUNT_MAP = [
  { key: "500", label: "500", value: 500 },
  { key: "1000", label: "1,000", value: 1000 },
  { key: "2000", label: "2,000", value: 2000 },
  { key: "5000", label: "5,000", value: 5000 },
  { key: "10000", label: "10,000", value: 10000 },
  // { key: "20000", label: "20,000", value: 20000 },
  // { key: "50000", label: "50,000", value: 50000 },
];

export const PAYMENT_OPTIONS = [
  {
    value: SP_PAYMENT_TYPES.CARD,
    label: "Pay with Debit Card",
    icon: payWithCard,
  },
  {
    value: SP_PAYMENT_TYPES.WALLET,
    label: "Pay with Bank Transfer",
    icon: payWithWallet,
  },
  // {
  //   value: "credit",
  //   label: "Borrow Xtracredit",
  //   icon: payWithCredit,
  // },
];

export const breadcrumbRoutes = [
  {
    key: 1,
    path: "index",
    name: "Input Details",
  },
  {
    key: 2,
    path: "",
    name: "Payment Details",
  },
  {
    key: 3,
    path: "",
    name: "Done",
  },
];

export const STATUS_RESPONSE = {
  INVALID: "invalid",
  FAILED: "failed",
};

export const getServiceLogo = memoizeOne((code) => {
  const { logo } = SERVICE_MAP[code] || {};
  return logo;
});

export const getDiscoFromRawData = (rawData = null) =>
  rawData ? JSON.parse(rawData)?.data?.disco : null;

export const contentRight = (isVerifying) => {
  return !isVerifying ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99314 11.6309C10.709 11.6309 14.3198 11.9367 14.3198 14.5825C14.3198 17.3696 10.1124 17.5368 8.30764 17.5469L7.65239 17.5469C5.72809 17.5369 1.66647 17.3706 1.66647 14.5992C1.66647 11.809 5.87388 11.6415 7.67863 11.6315L7.89547 11.6309C7.92926 11.6309 7.96184 11.6309 7.99314 11.6309ZM7.99314 12.8809C5.67731 12.8809 2.91647 13.1784 2.91647 14.5992C2.91647 15.7259 4.62481 16.2975 7.99314 16.2975C11.3615 16.2975 13.0698 15.72 13.0698 14.5825C13.0698 13.4534 11.3615 12.8809 7.99314 12.8809ZM15.5887 11.2416C17.8437 11.5791 18.3162 12.6233 18.3162 13.4391C18.3162 14.0466 18.0537 14.8691 16.8012 15.3433C16.7287 15.3708 16.6537 15.3841 16.5796 15.3841C16.3271 15.3841 16.0896 15.2299 15.9954 14.9808C15.8729 14.6583 16.0354 14.2966 16.3579 14.1749C17.0662 13.9066 17.0662 13.5791 17.0662 13.4391C17.0662 12.9666 16.5071 12.6433 15.4046 12.4791C15.0629 12.4274 14.8271 12.1091 14.8779 11.7683C14.9296 11.4266 15.2537 11.1974 15.5887 11.2416ZM7.99314 1.66669C10.3523 1.66669 12.2723 3.58669 12.2723 5.94585C12.2773 7.08252 11.8365 8.15585 11.0331 8.96419C10.2315 9.77335 9.16064 10.2209 8.02147 10.225H7.99314C5.63314 10.225 3.71314 8.30502 3.71314 5.94585C3.71314 3.58669 5.63314 1.66669 7.99314 1.66669ZM13.8995 2.60319C15.537 2.87152 16.7253 4.27235 16.7253 5.93319C16.722 7.60402 15.4745 9.03902 13.8228 9.27069C13.7936 9.27485 13.7645 9.27652 13.7353 9.27652C13.4286 9.27652 13.1611 9.05069 13.117 8.73819C13.0695 8.39652 13.307 8.07985 13.6486 8.03235C14.6886 7.88652 15.4736 6.98402 15.4753 5.93235C15.4753 4.88735 14.727 4.00569 13.6978 3.83735C13.357 3.78069 13.1261 3.45985 13.182 3.11902C13.2378 2.77819 13.5603 2.54902 13.8995 2.60319ZM7.99314 2.91669C6.32231 2.91669 4.96314 4.27585 4.96314 5.94585C4.96314 7.61585 6.32231 8.97502 7.99314 8.97502H8.01897C8.82231 8.97169 9.57897 8.65585 10.1465 8.08335C10.7148 7.51252 11.0256 6.75335 11.0223 5.94835C11.0223 4.27585 9.66314 2.91669 7.99314 2.91669Z"
        fill="#2C264B"
      />
    </svg>
  ) : (
    <Spinner />
  );
};

export const formatToken = (str = "", accountType = "") => {
  if (accountType === SP_ACCOUNT_TYPES.POSTPAID) {
    return "";
  }
  return str.replace(/(\d{4}(?=\d))/g, "$1-");
};

export const getAmount = (amount) => `₦ ${amount}`;
export const formatAccountbalance = (bal) => `₦ ${bal.toFixed(2)}`;

const SERVICE_TYPES = {
  POWER: "POWER",
};

export const getServiceTypeMap = {
  [SERVICE_TYPES.POWER]: "Electricity",
};

export const transactionData = [
  {
    date: "TUESDAY, 06 DEC 2022",
    transactionType: "electricity",
    transactionAmount: "15,000.00",
    transactionId: "SP-38475960",
    paymentMethod: "Sapa Wallet",
    transactionStatus: "Success",
    account: "12345678910",
    name: "OHIAERI KENECHUKWU",
    address: "16 SUNCITY ESTATE",
    token: "4854 9324 5933",
  },
  {
    date: "WEDNESDAY, 07 DEC 2022",
    transactionType: "internet",
    transactionAmount: "8,500.00",
    transactionId: "SP-38475961",
    paymentMethod: "Credit Card",
    transactionStatus: "Failed",
    account: "23456789101",
    name: "ABAH EKENE",
    address: "25 GREENVIEW AVENUE",
    token: "5932 4892 4335",
  },
  {
    date: "THURSDAY, 08 DEC 2022",
    transactionType: "cable",
    transactionAmount: "5,200.00",
    transactionId: "SP-38475962",
    paymentMethod: "Bank Transfer",
    transactionStatus: "Pending",
    account: "34567891012",
    name: "OBI CHINEDU",
    address: "10 PARKVIEW ESTATE",
    token: "3948 5923 8755",
  },
  {
    date: "FRIDAY, 09 DEC 2022",
    transactionType: "airtime",
    transactionAmount: "12,000.00",
    transactionId: "SP-38475963",
    paymentMethod: "Sapa Wallet",
    transactionStatus: "Success",
    account: "45678910123",
    name: "EZE NNAMDI",
    address: "7 ROYAL AVENUE",
    token: "2834 3294 5837",
  },
  {
    date: "FRIDAY, 09 DEC 2022",
    transactionType: "airtime",
    transactionAmount: "10,000.00",
    transactionId: "SP-38475800",
    paymentMethod: "Sapa Wallet",
    transactionStatus: "failed",
    account: "56789101234",
    name: "NWOSU CHIOMA",
    address: "12 SUNSET DRIVE",
    token: "4958 3928 3249",
  },
  {
    date: "SATURDAY, 10 DEC 2022",
    transactionType: "transfer",
    transactionAmount: "2,500.00",
    transactionId: "SP-38475964",
    paymentMethod: "Credit Card",
    transactionStatus: "Success",
    account: "67891012345",
    name: "IFEANYI CHUKWU",
    address: "3 LIBERTY STREET",
    token: "2948 5934 4852",
  },
  {
    date: "SUNDAY, 11 DEC 2022",
    transactionType: "exams",
    transactionAmount: "20,000.00",
    transactionId: "SP-38475965",
    paymentMethod: "Bank Transfer",
    transactionStatus: "Success",
    account: "78910123456",
    name: "ADEBAYO TEMITOPE",
    address: "9 MARYLAND AVENUE",
    token: "5823 3958 9324",
  },
];

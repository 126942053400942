import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message, Space } from "antd";
import SpSummaryCard from "../../sp-components/SpSummaryCard/SpSummaryCard";
import SpButton from "../../sp-components/SpButton/SpButton";
import SpDownloadReceiptButton from "../../sp-components/SpDownloadReceiptButton/SpDownloadReceiptButton";
import SpCopy from "../../sp-components/SpCopy/SpCopy";
import { formatAmount } from "../../helpers/numbers";
import { capitalize } from "lodash";
import Spinner from '../../ui/Spinner/Spinner';
import withUserData from "../../HOC/withUserData";
import usePusherNotification from "../../hooks/usePusherNotification";
import { formatDate } from "../../utils/utils";
import transactionInitImage from '../../assets/images/business_idea.png';

const { Title: CardTitle, Text: CardText } = SpSummaryCard;

const TransactionCompleted = ({ userData }) => {
    const location = useLocation();

    const navigate = useNavigate();
    const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false);

    const userId = userData?.userId ?? null;
    const txEventData = usePusherNotification(`app-notifications-${userId}`, 'TRANSACTION_NOTIFICATION');

    const txData = txEventData?.data ?? {};
    const txStatus = txData?.status?.toLowerCase();


    const {
        transactionReference = null,
        paymentStatus = "success"
    } = location?.state ?? {};

    const {
        amount,
        type,
        transaction_fee: transactionFee,
        service = {},
        created_at: createdAt,
        status,
        req: customer = {},
        res = {},
        transaction_reference: reference,
    } = txData;

    const {
        image_url: iconUrl = null,
    } = service;

    const {
        account_type: accountType = null,
        account = null,
        customer_address: customerAddress = null,
        name: customerName,
    } = customer;

    const {
        token,
        unit_purchased: unitPurchased,
    } = res?.info ?? (res ?? {});

    const handleSubmit = useCallback(() => {
        // Navigate back to home or perform any necessary action
    }, []);


    //Temporary fix to duplicate location entry in history
    useEffect(() => {
        if (!transactionReference) {
            navigate(-1);
        }
    }, [navigate, transactionReference]);

    useEffect(() => {
        let isCancelled = false;

        if (transactionReference !== txData?.transaction_reference) {
            return;
        }

        if (txStatus === "success" && !isCancelled) {
            message.success("Transaction successful!");

        }
        else if (txStatus === "failed" && !isCancelled) {
            message.error("Transaction failed! Please try again later!");
        }
        else {
            //message.info("Transaction is still processing. Please check back in the within 24hrs.");
        }

        setIsTransactionConfirmed(true);

        return () => {
            isCancelled = true;
        };

    }, [transactionReference, txStatus, txData, navigate]);


    return (
        <div style={{
            backgroundColor: '#fff',
            padding: "1.2rem 2rem",
            borderRadius: 12,
        }}>
            {
                !transactionReference &&
                <div style={{
                    display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '33vh',
                    textAlign: 'center'
                }}>
                    <h4 style={{ color: 'red' }}>Oops! Transaction error.</h4>

                </div>
            }

            {
                paymentStatus?.toLowerCase() === "cancelled" &&
                <div style={{
                    display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '33vh',
                    textAlign: 'center'
                }}>
                    <h4 style={{ color: 'red' }}>Oops! Payment cancelled</h4>

                </div>
            }

            {
                paymentStatus?.toLowerCase() !== "cancelled" && !isTransactionConfirmed && transactionReference &&
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    minHeight: '33vh',
                    textAlign: 'center',
                    padding: "2rem 2rem",

                }}>
                    <div style={{ marginBottom: 24 }}>
                        <img src={transactionInitImage} alt='' style={{
                            maxWidth: "205px"
                        }} />
                    </div>
                    <h4 style={{
                        fontSize: 20,
                    }}>Transaction initiated</h4>
                    <p style={{
                        color: "#696969",
                        maxWidth: "320px",
                        fontSize: 14,
                    }}>Your transaction with reference <span style={{ fontWeight: 600, color: "#696969" }}>{transactionReference}</span> is currently processing.</p>

                    <div style={{ marginTop: "32px" }}>
                        <Spinner size="large" />
                    </div>
                </div>
            }

            {
                paymentStatus?.toLowerCase() !== "cancelled" && isTransactionConfirmed && transactionReference && <>
                    <h3 style={{
                        fontWeight: 700,
                        fontSize: 22,
                        marginBottom: 16
                    }}>Payment Successful</h3>

                    <div className="sp-transaction-card" id="transaction-receipt-wrapper">
                        {iconUrl && (
                            <img
                                src={iconUrl}
                                alt={service}
                                style={{ width: 72, height: 72, borderRadius: "50%" }}
                            />
                        )}

                        {accountType && (
                            <div style={{ marginTop: 16 }}>
                                <span
                                    style={{
                                        display: "inline-block",
                                        width: 72,
                                        color: "#9747FF",
                                        backgroundColor: "rgba(151, 71, 255, 0.1)",
                                        textAlign: "center",
                                        fontSize: 11,
                                        lineHeight: "15.84px",
                                        fontWeight: 500,
                                        borderRadius: 26,
                                        padding: "8px 16px",
                                    }}
                                >
                                    {accountType}
                                </span>
                            </div>
                        )}

                        {amount && (
                            <div style={{ marginTop: 20 }}>
                                <CardTitle>Amount</CardTitle>
                                <CardText>{formatAmount(amount, "₦")}</CardText>
                            </div>
                        )}

                        <div className="sp-transaction-card__content" style={{ marginTop: 24 }}>
                            {account && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Beneficiary</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className="font-size-14px font-weight-semibold">{account}</CardText>
                                        <CardTitle className="font-size-14px">
                                            <p>{customerName}</p>
                                            <p style={{
                                                maxWidth: 200,
                                                marginTop: -4,
                                            }}>{customerAddress}</p>
                                        </CardTitle>
                                    </div>
                                </div>
                            )}

                            {token && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Token</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className="font-size-14px font-weight-semibold">
                                            <SpCopy
                                                text={token}
                                                successMessage="Token copied!"
                                                errorMessage="Failed to copy token!"
                                            />
                                            {token}
                                        </CardText>
                                    </div>
                                </div>
                            )}

                            {unitPurchased && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Units Purchased</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className="font-size-14px font-weight-semibold">{unitPurchased}</CardText>
                                    </div>
                                </div>
                            )}

                            {createdAt && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Date & Time</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className="font-size-14px font-weight-semibold">{formatDate(createdAt)}</CardText>
                                    </div>
                                </div>
                            )}

                            {reference && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Reference</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className="font-size-14px font-weight-semibold">{reference}</CardText>
                                    </div>
                                </div>
                            )}

                            {status && (
                                <div className="sp-transaction-card__row">
                                    <div>
                                        <CardTitle className="font-size-14px font-weight-medium">Status</CardTitle>
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                        <CardText className={`font-size-14px font-weight-semibold text-${status.toLowerCase()}`}>
                                            {capitalize(status)}
                                        </CardText>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: 40 }}>
                        <Space>
                            <SpDownloadReceiptButton contentHtmlId="transaction-receipt-wrapper" />
                            <SpButton type="outline" onClick={() => {
                                navigate('/')
                            }}>
                                Back to Home
                            </SpButton>
                        </Space>
                    </div>
                </>
            }
        </div>
    );
};

export default withUserData(TransactionCompleted);

import { LOCAL_STARAGE_KEYS, SERVER_TYPES } from "./config";
import { getItem } from "./localStorage";

const isGuest = true;
export const getDomain = () => (isGuest ? "" : "");

export const isStaging = () => process.env.ENV?.toLowerCase() !== "production";



export const getServer = async () => {
  const server = await getItem({ key: LOCAL_STARAGE_KEYS.CHOSEN_SERVER });
  return server || SERVER_TYPES.STAGING;
};

import React, { useCallback, useEffect, useRef, useState } from "react";
import SapaButton from "../../../../ui/Button/Button";
import Dropdown from "../../../../ui/Dropdown/Dropdown";
import Icon from "../../../../assets/Icon";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { editRecurringPayment } from "../../../../api/user";
import { hideModal } from "../../../../redux/actions/modal";
import SuccessModal from "../SuccessModal";
import { getSelectedRecurringPayment } from "../../../../redux/selectors/user";

export const RECURRING_PAYMENTS_OPTIONS = [
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
];

const RecurringPayments = ({ refetch }) => {
  const currentRecurringPayment = useSelector(getSelectedRecurringPayment);
  const { id, frequency: timeline, startDate } = currentRecurringPayment || {};

  const [frequency, setFrequency] = useState(
    timeline || RECURRING_PAYMENTS_OPTIONS[0].value
  );
  const [display, setDisplay] = useState("success");
  const [displayWrapper, setDisplayWrapper] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const handleCalendarClick = () => {
    inputRef?.current?.showPicker();
  };

  const containerStyle = { marginTop: 20 };

  const handleAccountType = useCallback((e) => {
    setFrequency(e);
  }, []);

  const formatUploadDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    return dateObject?.toISOString();
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const response = await editRecurringPayment(id, {
        frequency: frequency,
        startDate: inputRef?.current?.value
          ? formatUploadDate(inputRef?.current?.value)
          : startDate,
      });
      if (response.status === 200) {
        refetch(Date.now());
        setLoading(false);
        message.success("Successfully Edited Schedule");
        setTimeout(() => {
          dispatch(hideModal());
        }, 100);
      }
    } catch (editScheduleError) {
      setLoading(false);
      message.error(editScheduleError?.message || "Something went wrong");
    }
  }, [frequency, inputRef?.current]);

  const handleEdit = () => {
    setDisplay("edit");
  };

  const handleChange = () => {
    if (displayWrapper) {
      setDisplayWrapper(false);
    }
  };

  useEffect(() => {
    if (window.location.pathname !== "/electricity") {
      setDisplay("edit");
    }
    if (timeline) {
      setFrequency(timeline);
    }
  }, [currentRecurringPayment]);

  return (
    <div className="modal_components">
      {display === "success" ? (
        <>
          <SuccessModal
            title={"Payment scheduled!"}
            subtitle={"The recurring payment was setup successfully."}
          />
          <div className="edit-schedule-btn-container">
            <SapaButton
              label={
                <span>
                  {" "}
                  <Icon icon={"calendar"} width={16} height={16} />
                  Edit Schedule
                </span>
              }
              onClick={handleEdit}
              buttonType="bordered"
            />
          </div>
        </>
      ) : (
        <>
          <div className="centered_text">
            <h3>Edit Schedule</h3>
            <p>Please select the frequency and start date of your payment</p>
          </div>
          <div>
            <Dropdown
              label="Frequency"
              options={RECURRING_PAYMENTS_OPTIONS}
              value={frequency}
              handleChange={handleAccountType}
              containerStyle={containerStyle}
              suffixIcon={<Icon icon={"chevron-down"} width={12} height={12} />}
              disabled={false}
            />
          </div>
          <div className="date-btn-container">
            <p>Start date</p>
            <button>
              {displayWrapper && window.location.pathname === "/account" && (
                <p className="absolute-date">{formatDate(startDate)}</p>
              )}
              <input type="date" ref={inputRef} onChange={handleChange} />
              <Icon
                onClick={handleCalendarClick}
                icon={"calendar"}
                width={16}
                height={16}
              />
            </button>
          </div>
          <div className="modal_components__button">
            <SapaButton
              label="Save changes"
              onClick={handleSubmit}
              customClass="full-width"
              loading={loading}
            />
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default RecurringPayments;

import React from "react";
import { ReactComponent as Edit } from "./svgs/Edit.svg";
import { ReactComponent as Delete } from "./svgs/Delete 1.svg";
import { ReactComponent as CheckCircle } from "./svgs/Check circle 1.svg";
import { ReactComponent as Checkmark } from "./svgs/Checkmark.svg";
import { ReactComponent as ArrowRight } from "./svgs/Arrow---Right.svg";
import { ReactComponent as QuestionMarkCircle } from "./svgs/question mark circle 1.svg";
import { ReactComponent as Calling } from "./svgs/Calling.svg";
import { ReactComponent as EmailOpen } from "./svgs/email open 1.svg";
import { ReactComponent as MessageBubble } from "./svgs/messages bubble 1.svg";
import { ReactComponent as MessageQuestion } from "./svgs/messages question 1.svg";
import { ReactComponent as Copy1 } from "./svgs/Copy 1.svg";
import { ReactComponent as SeenCheckmark } from "./svgs/Seen checkmark.svg";
import { ReactComponent as ErrorCircle } from "./svgs/Error circle.svg";
import { ReactComponent as Profile } from "./svgs/Profile.svg";
import { ReactComponent as BuildingOffice } from "./svgs/building-office.svg";
import { ReactComponent as ChevronRight } from "./svgs/chevron-right.svg";
import { ReactComponent as ChevronDown } from "./svgs/chevron-down.svg";
import { ReactComponent as Calendar } from "./svgs/Calendar.svg";
import { ReactComponent as Copy } from "./svgs/main-copy.svg";

const Icon = ({ width, height, rotation, icon, onClick }) => {
  const uIcons = {
    edit: Edit,
    delete: Delete,
    "check-circle": CheckCircle,
    checkmark: Checkmark,
    "arrow-right": ArrowRight,
    "question-mark-circle": QuestionMarkCircle,
    calling: Calling,
    "email-open": EmailOpen,
    "message-bubble": MessageBubble,
    "message-question": MessageQuestion,
    copy1: Copy1,
    "seen-checkmark": SeenCheckmark,
    "error-circle": ErrorCircle,
    profile: Profile,
    "building-office": BuildingOffice,
    "chevron-right": ChevronRight,
    "chevron-down": ChevronDown,
    calendar: Calendar,
    copy: Copy,
  };
  const IconComponent = uIcons[icon];

  if (!IconComponent) {
    return null;
  }
  return (
    <IconComponent
      style={{
        cursor: "pointer",
        width: width,
        height: height,
        maxWidth: width,
        minWidth: width,
        maxHeight: height,
        minHeigh: height,
        transform: rotation,
      }}
      onClick={onClick}
    />
  );
};

export default Icon;

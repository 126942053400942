import _isEmpty from "lodash/isEmpty";

export const getUserReducer = (state) => state.user;
export const getCurrentUser = (state) => getUserReducer(state)?.current;
export const getIsGuestUser = (state) =>
  _isEmpty(getUserReducer(state).current);

export const getCurrentBeneficiary = (state) =>
  getUserReducer(state).currentBeneficiary;

export const getSelectedRecurringPayment = (state) =>
  getUserReducer(state).selectedRecurringPayment;

import apiClient from "./apiClient";

// Function to send OTP
export const sendOtpRequest = (data) =>
  apiClient({
    method: "POST",
    url: "/verification/otp/send",
    data,
  });

// Function to verify OTP
export const verifyOtpRequest = (data) =>
  apiClient({
    method: "POST",
    url: "/verification/otp/verify",
    data,
  });

import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  VALIDATE_ACCOUNT,
  VALIDATE_ACCOUNT_RESET,
} from '../types';

// Action creator for validating account with promise action
export const validateAccount = createPromiseAction(VALIDATE_ACCOUNT);

// Action creator to reset account validation state
export const validateAccountReset = () => ({
  type: VALIDATE_ACCOUNT_RESET,
});

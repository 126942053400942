
import React from 'react';
import PropTypes from 'prop-types';

const SpMessage = ({ type, children, className, visible, ...rest }) => {
  return visible && <div className={`sp-message ${className} ${type}`} {...rest}>{children}</div>;
};

SpMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'danger', 'info']).isRequired,
  children: PropTypes.node,
  visibile: PropTypes.bool,
  className: PropTypes.string,
};

SpMessage.defaultProps = {
  visible: false,
  className: '',
}

export default SpMessage;

import { Outlet, useLocation } from "react-router-dom";
import SpSteps from "../../../sp-components/SpSteps/SpSteps";
import { pathToIndex } from '../../../utils/utils';

//Order of precedence
const tabs = [
  "electricity", "payment-details", "done"
];

const Electricity = () => {
  const location = useLocation();
  const pathName = location.pathname;

  const stepItems = [
    { title: "INPUT DETAILS", icon: <></> },
    { title: "PAYMENT DETAILS", icon: <></> },
    { title: "DONE", icon: <></> }
  ];

  return (
    <div className="bg-white">
      <SpSteps items={stepItems} current={pathToIndex(pathName, tabs)} />
      <Outlet />
    </div>
  );
};

export default Electricity;

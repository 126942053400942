import React, { createContext, useContext, useState, useCallback } from 'react';
import ModalContent from '../components/Modal/ModalComponents/ModalContent';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalProps, setModalProps] = useState({});

  const showModal = useCallback((Content, props = {}) => {
    setModalContent(() => Content);
    setModalProps(props);
  }, []);

  const closeModal = useCallback(() => {
    setModalContent(null);
    setModalProps({});
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      {modalContent && (
        <ModalContent content={modalContent} {...modalProps} onClose={closeModal} />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);

const transferIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8562 2.1486C17.4395 1.72277 16.8228 1.56527 16.2478 1.73194L2.83951 5.6061C2.23284 5.77444 1.80284 6.25527 1.68701 6.86527C1.56867 7.48694 1.98201 8.27694 2.52201 8.60694L6.71451 11.1669C7.14451 11.4303 7.69951 11.3644 8.05534 11.0078L12.8562 6.20694C13.0978 5.9561 13.4978 5.9561 13.7395 6.20694C13.9812 6.44777 13.9812 6.84027 13.7395 7.09027L8.93034 11.8911C8.57367 12.2478 8.50701 12.8011 8.76951 13.2319L11.3312 17.4403C11.6312 17.9394 12.1478 18.2236 12.7145 18.2236C12.7812 18.2236 12.8562 18.2236 12.9228 18.2144C13.5728 18.1319 14.0895 17.6894 14.2812 17.0644L18.2562 3.75694C18.4312 3.19027 18.2728 2.5736 17.8562 2.1486Z"
      fill={fill}
    />
    <path
      opacity="0.4"
      d="M7.87618 15.9519C8.11951 16.196 8.11951 16.5919 7.87618 16.836L6.73785 17.9735C6.61618 18.096 6.45618 18.1569 6.29618 18.1569C6.13618 18.1569 5.97618 18.096 5.85451 17.9735C5.61035 17.7294 5.61035 17.3344 5.85451 17.0902L6.99201 15.9519C7.23618 15.7085 7.63201 15.7085 7.87618 15.9519ZM7.2231 12.7952C7.46643 13.0394 7.46643 13.4352 7.2231 13.6794L6.08476 14.8169C5.9631 14.9394 5.8031 15.0002 5.6431 15.0002C5.4831 15.0002 5.3231 14.9394 5.20143 14.8169C4.95726 14.5727 4.95726 14.1777 5.20143 13.9335L6.33893 12.7952C6.5831 12.5519 6.97893 12.5519 7.2231 12.7952ZM4.08876 11.8015C4.3321 12.0457 4.3321 12.4415 4.08876 12.6857L2.95043 13.8232C2.82876 13.9457 2.66876 14.0065 2.50876 14.0065C2.34876 14.0065 2.18876 13.9457 2.0671 13.8232C1.82293 13.579 1.82293 13.184 2.0671 12.9399L3.2046 11.8015C3.44876 11.5582 3.8446 11.5582 4.08876 11.8015Z"
      fill={fill}
    />
  </svg>
);

export default transferIcon;

import { createReducer } from '@reduxjs/toolkit';
import {
  initiateTransaction,
  fetchTransactionDetails,
  fetchTransactionList,
} from '../actions/transactions'; 


import { SP_PAYMENT_TYPES } from '../../pages/Services/Services.helpers';
import { SET_PAYMENT_TYPE, TRANSACTION_FORM_DATA_RESET, UPDATE_TRANSACTION_FORM_DATA } from '../types';

const initialState = {
  paymentType: SP_PAYMENT_TYPES.CARD,
  transactionFormData: {},
  transactionInit: {
    loading: false,
    error: null,
    data: {},
  },
  transactionDetails: {
    loading: false,
    error: null,
    data: {},
  },
  transactionList: {
    loading: false,
    error: null,
    data: {},
  },
};

const transactionReducer = createReducer(initialState, {
  [SET_PAYMENT_TYPE]: (state, action) => {
    state.paymentType = action.payload.paymentType;
  },
  [UPDATE_TRANSACTION_FORM_DATA]: (state, action) => {
    state.transactionFormData = {
      ...state.transactionFormData,
      ...action.payload,
    };
  },
  [TRANSACTION_FORM_DATA_RESET]: (state) => {
    state.transactionFormData = {};
  },

  // Initiate transaction actions
  [initiateTransaction.trigger]: (state) => {
    state.transactionInit.loading = true;
    state.transactionInit.error = null;
    state.transactionInit.data = {};
  },
  [initiateTransaction.resolved]: (state, action) => {
    state.transactionInit.loading = false;
    state.transactionInit.data = action.payload;
  },
  [initiateTransaction.rejected]: (state, action) => {
    state.transactionInit.loading = false;
    state.transactionInit.error = action.payload;
  },
  [TRANSACTION_FORM_DATA_RESET]: (state) => {
    state.transactionInit = initialState.transactionInit;
  },

  // Fetch transaction details actions
  [fetchTransactionDetails.trigger]: (state) => {
    state.transactionDetails.loading = true;
    state.transactionDetails.error = null;
  },
  [fetchTransactionDetails.resolved]: (state, action) => {
    state.transactionDetails.loading = false;
    state.transactionDetails.data = action.payload;
  },
  [fetchTransactionDetails.rejected]: (state, action) => {
    state.transactionDetails.loading = false;
    state.transactionDetails.error = action.payload;
  },

  // Fetch transaction list actions
  [fetchTransactionList.trigger]: (state) => {
    state.transactionList.loading = true;
    state.transactionList.error = null;
  },
  [fetchTransactionList.resolved]: (state, action) => {
    state.transactionList.loading = false;
    state.transactionList.data = action.payload;
  },
  [fetchTransactionList.rejected]: (state, action) => {
    state.transactionList.loading = false;
    state.transactionList.error = action.payload;
  },
});

export default transactionReducer;

import React from 'react';
import PropTypes from 'prop-types';

const SpOptionButton = ({ label, isActive, onClick, disabled, icon }) => {
    return (
        <div
            className={`sp-option-button ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
            onClick={!disabled ? onClick : undefined}
        >
            <div className="sp-option-button__content">
                {icon && <div className="sp-option-button__icon">{icon}</div>}
                <div className="sp-option-button__label">{label}</div>
            </div>
            {isActive ? (
                <div className="sp-option-button__checkmark">&#10003;</div>
            ) : (
                <div className="sp-option-button__uncheckmark"></div>
            )}
        </div>
    );
};

SpOptionButton.propTypes = {
    label: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.node, // PropTypes for icon prop
};

SpOptionButton.defaultProps = {
    disabled: false,
    icon: null, // Default icon prop to null
};

export default SpOptionButton;

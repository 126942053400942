import React, { useCallback } from "react";

import CharacterStanding from "../../assets/svgs/character-standing.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNavPath } from "../../routes/routes.helpers";
import { NAV_LOGIN, NAV_SIGN_UP } from "../../routes/routes.constant";

import { useFormik } from "formik";
import * as yup from 'yup';
import { sendOtp } from "../../redux/actions/otpVerification";
import SpButton from "../../sp-components/SpButton/SpButton";
import SpInput from "../../sp-components/SpInput/SpInput";
import SpMessage from "../../sp-components/SpMessage/SpMessage";

const validationSchema = yup.object({
  phone_number: yup.string()
    .min(10, "Please enter a valid phone number")
    .max(14, "Please enter a valid phone number")
    .required('Phone number is required'),
})

function SignIn(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpSendState = useSelector(state => state.otpVerification.send);

  const {
    loading: otpSendLoading,
  } = otpSendState;

  const initialFormData = {
    otp_meta: {
      channel: "SMS",
      action: "LOGIN_WITH_USERNAME_AND_OTP",
    },
  };

  const handleSubmit = useCallback(values => {
    // Dispatch the sendOtp action
    dispatch(sendOtp({
      ...initialFormData.otp_meta,
      recipient: values.phone_number
    }))
      .then(() => {
        // Navigate to the verification page upon successful OTP dispatch
        navigate(getNavPath(NAV_LOGIN + '/verify'), { state: values });
      })
      .catch((error) => {
        // Handle any errors that occur during the OTP dispatch
        console.error('Failed to send OTP:', error);
      });

  }, []);

  const formik = useFormik({
    initialValues: initialFormData,
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleChange = useCallback((field, value) => {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  }, [formik, dispatch]);

  const handleBlur = useCallback((field) => {
    formik.setFieldTouched(field, true);

  }, [formik]);

  const formIsValid = formik.isValid && !otpSendLoading;

  const loading = otpSendLoading;

  return (
    <div className="auth">
      <form onSubmit={formik.handleSubmit}>
        <div className="centered_text" style={{ margin: 40 }}>
          <h3>Welcome Back</h3>
          <p>Enter your mobile number to get started</p>
        </div>

        <div style={{ maxWidth: 380, display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
          <SpInput
            numericOnly
            value={formik.values.phone_number}
            onChange={e => handleChange("phone_number", e.target.value)}
            onBlur={() => handleBlur("phone_number")}
            //placeholder="E.g 08189031845"
            className='sp-outlined'
            maxLength={11}
          />
          <SpMessage
            type='danger'
            visible={formik.touched.phone_number && formik.errors.phone_number}
          >
            {formik.errors.phone_number}
          </SpMessage>
        </div>
        <div className="auth__button">
          <SpButton loading={loading} disabled={!formIsValid} htmlType='submit'>Continue</SpButton>
        </div>

        <br />
        {<p
          onClick={() => navigate(getNavPath(NAV_SIGN_UP))}
          className="text-align-center"
        >
          Don't have an Account? <a href={getNavPath(NAV_SIGN_UP)}>Sign Up</a>
        </p>}
      </form>

      <img className="character" src={CharacterStanding} alt="" />
    </div>
  );
}

export default SignIn;

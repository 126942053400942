import React from "react";
import { Input, Row } from "antd";
import PropTypes from "prop-types";

const SapaInput = ({
  label,
  placeholder,
  inputStyles,
  onChange,
  containerStyle,
  contentRight,
  bottomLabel,
  value = "",
  inputClass,
  wrapperClass,
  autoFocus,
  contentLeft,
  disabled = false,
  inputName,
  inputMode,
  validationMsg,
  pattern,
  id,
  handleContentRightClick,
}) => {
  return (
    <div style={containerStyle}>
      {/* <Row>
      <label>{label}</label>
    </Row> */}
      <Row className={`input_wrapper ${wrapperClass}`}>
        <label>{label}</label>
        <Input
          className={`input ${inputClass}`}
          value={value}
          name={inputName}
          style={inputStyles}
          placeholder={placeholder}
          onChange={onChange}
          suffix={
            <i className="suffix-container" onClick={handleContentRightClick}>
              {contentRight}
            </i>
          }
          prefix={contentLeft}
          autoFocus={autoFocus}
          disabled={disabled}
          inputMode={inputMode}
          status={!!validationMsg && "error"}
          pattern={pattern}
          id={id}
        />
        {/* {contentRight && <div className="input__icon_right">{contentRight}</div>} */}
      </Row>
      {!!validationMsg && <span className="text-error">{validationMsg}</span>}
      {!!bottomLabel && bottomLabel}
    </div>
  );
};

SapaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  containerStyle: PropTypes.object,
  contentRight: PropTypes.object,
  bottomLabel: PropTypes.object,
  autoFocus: PropTypes.bool,
  contentLeft: PropTypes.object,
  disabled: PropTypes.bool,
  inputName: PropTypes.string,
  validationMsg: PropTypes.string,
};

SapaInput.defaultProps = {
  autoFocus: false,
};

export default SapaInput;

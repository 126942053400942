import React, { useCallback } from "react";
import { CopyIcon } from "../../components/Svgs";
import { message } from "antd";
import PropTypes from 'prop-types';
import SpButton from "../SpButton/SpButton";


const SpCopy = ({text, successMessage, errorMessage, useButton, children, className, ...rest}) => {
    
    const elementType = useButton ? SpButton : 'span';
    const elementProps = {
        className: `sp-copy__button ${className}`,
        ...rest
    }

    const content = <><CopyIcon />{children}</>;

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(text).then(
            () => {
                message.success(successMessage);
            },
            (err) => {
                message.error(errorMessage);
            }
        );
    }, [text, successMessage, errorMessage, navigator]);
  
    return React.createElement(elementType, {...elementProps, onClick: handleCopy}, content);
}

SpCopy.propTypes = {
    text: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    className: PropTypes.string,
    useButton: PropTypes.bool,
    label: PropTypes.string,
};

SpCopy.defaultProps = {
    successMessage: "Text copied successfully",
    errorMessage: "Failed to copy text!",
    className: "",
    useButton: false
}

export default SpCopy;
import React from "react";
const PayBills = ({ onClick, size = 20 }) => {
  return (
    <svg
      style={{ width: size, height: size }}
      onClick={onClick}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.985054"
        y="0.381348"
        width="26.7623"
        height="26.7623"
        rx="8.0287"
        fill="#9747FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6017 8.52832H11.1332C9.41825 8.52832 8.26661 9.73391 8.26661 11.5278V11.5792C8.26661 11.6856 8.35288 11.7719 8.4593 11.7719H20.2763C20.3827 11.7719 20.4689 11.6856 20.4689 11.5792V11.5278C20.4689 9.73391 19.3167 8.52832 17.6017 8.52832Z"
        fill="#FDFDFD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7242 15.2344C13.5353 15.0456 13.2303 15.0469 13.0427 15.2344L10.4459 17.8351L9.36109 16.7502C9.17289 16.562 8.8678 16.562 8.67961 16.7502C8.49206 16.9384 8.49206 17.2435 8.67961 17.4317L10.1055 18.8576C10.1961 18.9475 10.3181 18.9983 10.4459 18.9983C10.5744 18.9983 10.6964 18.9475 10.787 18.8576L13.7242 15.9159C13.9124 15.7277 13.9124 15.422 13.7242 15.2344Z"
        fill="#FDFDFD"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.26347 15.348C8.26347 15.4933 8.42054 15.5889 8.56005 15.548C9.10558 15.3881 9.72536 15.5239 10.1582 15.9513L10.3108 16.1038C10.3861 16.1792 10.5083 16.1791 10.5835 16.1036L12.2457 14.4355C12.8751 13.8125 13.89 13.806 14.5194 14.4355C15.1424 15.0649 15.1489 16.0798 14.5194 16.7092L12.811 18.423C12.6899 18.5445 12.776 18.7517 12.9475 18.7517H17.6024C19.3174 18.7517 20.4671 17.5442 20.4671 15.7522V12.9261C20.4671 12.8197 20.3808 12.7334 20.2744 12.7334H8.45615C8.34974 12.7334 8.26347 12.8197 8.26347 12.9261V15.348Z"
        fill="#FDFDFD"
      />
    </svg>
  );
};
export default PayBills;

const accountIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99733 12.6455C6.40316 12.6455 3.33316 13.2122 3.33316 15.4788C3.33316 17.7463 6.38399 18.333 9.99733 18.333C13.5915 18.333 16.6615 17.7672 16.6615 15.4997C16.6615 13.2322 13.6115 12.6455 9.99733 12.6455Z"
      fill={fill}
    />
    <path
      opacity="0.4"
      d="M9.99734 10.4864C12.4457 10.4864 14.4073 8.52394 14.4073 6.07644C14.4073 3.62894 12.4457 1.66644 9.99734 1.66644C7.54984 1.66644 5.58734 3.62894 5.58734 6.07644C5.58734 8.52394 7.54984 10.4864 9.99734 10.4864Z"
      fill={fill}
    />
  </svg>
);

export default accountIcon;

import { call, takeEvery } from "redux-saga/effects";
import { SHOW_MODAL } from "../types";

export function* showModal() {
  yield call(() => console.log("hello there"));
}

export default function* modalSaga() {
  yield takeEvery(SHOW_MODAL, showModal);
}

import React from 'react';
import PropTypes from 'prop-types';

const SpSummaryCard = ({ renderLeftColumn, renderRightColumn }) => {
    return (
        <div className="sp-summary-card">
            <div className="sp-summary-card__content">
                <div className="sp-summary-card__left-column">
                    {renderLeftColumn()}
                </div>
                <div className="sp-summary-card__right-column">
                    {renderRightColumn()}
                </div>
            </div>
        </div>
    );
}

SpSummaryCard.propTypes = {
    renderLeftColumn: PropTypes.func,
    renderRightColumn: PropTypes.func,
};

SpSummaryCard.defaultProps = {
    renderLeftColumn: () => {},
    renderRightColumn: () => {},
};

SpSummaryCard.Title = ({ children, className, ...rest }) => {
    return <h4 {...rest} className={`sp-summary-card__title ${className}`}>{children}</h4>;
};

SpSummaryCard.Title.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

SpSummaryCard.Title.defaultProps = {
    children: null,
    className: '',
};


SpSummaryCard.Text = ({ children, className, ...rest }) => {
    return <p {...rest} className={`sp-summary-card__text ${className}`}>{children}</p>;
};

SpSummaryCard.Text.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};

SpSummaryCard.Text.defaultProps = {
    children: null,
    className: ''
};


export default SpSummaryCard;

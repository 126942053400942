import { Dropdown, Menu, message } from "antd";
import React, { useCallback, useState } from "react";
import Chevron from "../../../../assets/svgs/svg-icons/Chevron";
import SapaInput from "../../../../ui/Input/Input";
import SapaButton from "../../../../ui/Button/Button";
import nationalID from "../../../../assets/images/national-id.png";
import passport from "../../../../assets/images/international-passport.png";
import votersCard from "../../../../assets/images/voters-card.png";
import { verifyNIN } from "../../../../api/user";
import SuccessModal from "../SuccessModal";
import { current } from "@reduxjs/toolkit";

const VerifyIDCard = ({ currentDisplay }) => {
  const [cardNumber, setCardNumber] = useState(null);
  const [filter, setFilter] = useState({
    label: "National Identity Card",
  });
  const [visible, setVisible] = useState(false);
  const [display, setDisplay] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";

  const successMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.open({
        key,
        type: "success",
        content: "ID submitted succesfully",
        duration: 2,
      });
    }, 1000);
  };

  const errorMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Loading...",
    });
    setTimeout(() => {
      messageApi.error({
        key,
        type: "error",
        content: "Something went wrong",
        duration: 2,
      });
    }, 1000);
  };

  const { label } = filter;

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const handleChange = useCallback((e) => {
    setCardNumber(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    console.log(label);
    if (label.trim() === "National Identity Card") {
      try {
        const cardInfo = {
          cardNumber: cardNumber,
        };

        const response = await verifyNIN(cardInfo);
        if (response.status === 200) {
          successMessage();
          currentDisplay("successful");
          setDisplay(1);
        }
      } catch (error) {
        console.error(error);
        errorMessage();
      }
    } else return;
  }, [cardNumber, display, label]);

  const menuItems = [
    { label: "National Identity Card" },
    { label: "International Passport" },
    { label: "Voters Card" },
  ];

  const menu = (
    <Menu>
      {menuItems.map((item, index) => (
        <Menu.Item
          onClick={() => {
            setFilter({
              label: item.label,
            });
            setVisible(!visible);
          }}
          key={index}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  function handleVisibleChange(visible) {
    setVisible(visible);
  }

  const imageSources = {
    "National Identity Card": nationalID,
    "International Passport": passport,
    "Voters Card": votersCard,
  };

  return (
    <div className="modal_components">
      {contextHolder}
      {display === 0 && (
        <>
          <div className="centered_text">
            <h3>ID Card Verification</h3>
            <p>
              Please fill the important details below to complete the level 2
              verification
            </p>
          </div>

          <img src={imageSources[label]} alt={label} />

          <div className="filter-container">
            <label className="filter-container_label">Choose ID Type</label>
            <button
              type="button"
              className="filter-container_button"
              onClick={handleButtonClick}
            >
              {label} <Chevron />
            </button>
            {visible && <div className="overlay" />}
            {visible ? (
              <Dropdown
                overlay={menu}
                visible={visible}
                onVisibleChange={handleVisibleChange}
                trigger={["click"]}
              >
                <span></span>
              </Dropdown>
            ) : (
              ""
            )}
          </div>

          <SapaInput
            value={cardNumber}
            inputMode="numeric"
            onChange={handleChange}
            autoFocus
            label={"Enter ID Number"}
            pattern="[0-9]*"
          />

          <div className="modal_components__button">
            <SapaButton
              label="Submit"
              onClick={handleSubmit}
              customClass="full-width"
            />
          </div>
        </>
      )}
      {display === 1 && (
        <SuccessModal
          title={"ID submitted"}
          subtitle={"Your submission has been received and approved"}
        />
      )}
    </div>
  );
};

export default VerifyIDCard;

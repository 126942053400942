import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import _noop from "lodash/noop";

const SapaButton = ({
  label,
  shape = "default",
  customClass,
  onClick,
  buttonType = "primary",
  loading = false,
  size = "lg",
  disabled,
}) => {
  let buttonStyle = "";
  let buttonSize = "";

  switch (buttonType) {
    case "primary":
      buttonStyle = "button__primary";
      break;
    case "transparent":
      buttonStyle = "button__transparent";
      break;
    case "cancel-light":
      buttonStyle = "button__cancel-light";
      break;
    case "cancel-dark":
      buttonStyle = "button__cancel-dark";
      break;
    case "bordered":
      buttonStyle = "button__bordered";
      break;
    default:
      break;
  }
  if (size === "sm") {
    buttonSize = "button__sm";
  }

  return (
    <Button
      className={`button ${buttonStyle} ${buttonSize} ${customClass}`}
      type="primary"
      shape={shape}
      size={20}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
    >
      <span>{label}</span>
    </Button>
  );
};

SapaButton.propTypes = {
  label: PropTypes.string,
  shape: PropTypes.oneOf(["circle", "default", "round"]),
  customClass: PropTypes.string,
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
  loading: PropTypes.bool,
};

SapaButton.defaultProps = {
  onClick: _noop,
};

export default SapaButton;

import _map from "lodash/map";
import _join from "lodash/join";
import _includes from "lodash/includes";

const INPUT_MASK_INDEX = [6, 7, 8];

export const maskNumber = (value) => {
  const maskedDigits = _join(
    _map(value, (val, i) => {
      if (_includes(INPUT_MASK_INDEX, i)) {
        val = "*";
      }
      return val;
    }),
    ""
  );
  return maskedDigits;
};

export const validateNumber = (value) => {
  const isMatch = /^([0|+[0-9]{1,4})?([7-9][0-1][0-9]{8})$/.test(value);
  return isMatch;
};

export function formatAmount(x, currency = '', decimalPlaces = 2) {
  if (x == null) return '';
  
  const formattedNumber = Number(x).toFixed(decimalPlaces);

  // Add commas for thousands separation
  const parts = formattedNumber.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return currency + parts.join('.');
}

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import OtpInput from "react-otp-input";
import SpMessage from "../../../../../sp-components/SpMessage/SpMessage";
import SpButton from "../../../../../sp-components/SpButton/SpButton";
import { NAV_UPDATE_TRANSACTION_PIN } from "../../../../../routes/routes.constant";
import { sendOtp, verifyOtp } from "../../../../../redux/actions/otpVerification";


const UpdateTransactionPinVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const otpLength = 6;

  const [otp, setOtp] = useState("");
  const [countdownTimer, setCountdownTimer] = useState(0);

  const formData = location.state || {};

  const otpSendState = useSelector(state => state.otpVerification.send);
  const otpVerifyState = useSelector(state => state.otpVerification.verify);

  const { loading: otpSendLoading } = otpSendState;
  const { loading: otpVerifyLoading, error: otpVerifyError } = otpVerifyState;

  const handleSubmit = useCallback(() => {
    dispatch(verifyOtp({
      ...formData.otp_meta,
      recipient: formData.phone_number,
      otp_code: otp
    }))
      .then((response) => {
        message.success(response?.message);
        navigate(NAV_UPDATE_TRANSACTION_PIN, {state: formData});
      })
      .catch(_ => {
        setOtp("");
      });
  }, [dispatch, formData, otp]);


  const handleKeyUp = useCallback((event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }, []);

  useEffect(() => {
    if (countdownTimer > 0) {
      const timerId = setTimeout(() => setCountdownTimer(prev => prev - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [countdownTimer]);

  const resendCode = useCallback(() => {
    // Set resending to true to prevent duplicate requests
    setCountdownTimer(60);

    // Dispatch the action and handle the promise
    dispatch(sendOtp({ ...formData.otp_meta, recipient: formData.phone_number }))
      .then(() => {
        setOtp("");
      })
      .catch(error => {
        //Do something later
      })
      .finally(() => {
        //Do something later
      });
  }, [dispatch, formData]);


  const loading = otpSendLoading || otpVerifyLoading;
  const isDisabled = loading || otp.length !== otpLength;

  return (
    <div className="update-transaction-pin">
    
          <h3 style={{marginTop: "64px"}}>Verify OTP</h3>
          <p style={{marginTop: 16}}>Please enter the code we sent to you</p>
          <div style={{marginTop: "24px"}}></div>
          <OtpInput
              isDisabled={loading}
              value={otp}
              onChange={setOtp}
              numInputs={otpLength}
              className="otp-input"
              separator={<div style={{ marginRight: 15 }} />}
              shouldAutoFocus
              isInputNum
              onKeyUp={handleKeyUp}
            />
          <SpMessage
            type='danger'
            visible={!!otpVerifyError && !loading}
            style={{ marginLeft: -200 }}
          >
            {otpVerifyError}
          </SpMessage>
          <div style={{marginTop: "16px"}}></div>
          {countdownTimer === 0 ? (
            <p onClick={resendCode} className="resend text-align-center">Resend Code</p>
          ) : (
            <p className="resend text-align-center">Resending in {countdownTimer}</p>
          )}
          <div className="auth__button">
            <SpButton loading={loading} disabled={isDisabled} onClick={handleSubmit}>Continue</SpButton>
          </div>
        </div>
  );
}

export default UpdateTransactionPinVerify;

import { call, all, takeEvery } from 'redux-saga/effects';
import { initiateTransactionRequest } from '../../api/transaction'; // API import
import { initiateTransaction } from '../actions/transactions'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils';
import { message } from 'antd';

// Saga to handle initiating a transaction
export function* handleInitiateTransaction(action) {
  try {
    const { data } = yield call(initiateTransactionRequest, action.payload);
    yield call(resolvePromiseAction, action, data?.data ?? data); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    message.error(errorMessage);
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for transaction actions
export default function* transactionSaga() {
  yield all([
    takeEvery(initiateTransaction.trigger, handleInitiateTransaction), // Watch for initiate transaction action
  ]);
}

import { createReducer } from '@reduxjs/toolkit';
import {
  fetchServiceCategories,
  fetchServicesByCategory,
} from '../actions/service'; // Update import path as needed

const initialState = {
  categories: {
    data: [],
    loading: false,
    error: null,
  },
  services: {
    data: [],
    loading: false,
    error: null,
  },
};

const serviceReducer = createReducer(initialState, {
  // Fetch service categories actions
  [fetchServiceCategories.trigger]: (state) => {
    state.categories.loading = true;
    state.categories.error = null;
  },
  [fetchServiceCategories.resolved]: (state, action) => {
    state.categories.loading = false;
    state.categories.data = action.payload;
  },
  [fetchServiceCategories.rejected]: (state, action) => {
    state.categories.loading = false;
    state.categories.error = action.payload;
  },

  // Fetch services by category actions
  [fetchServicesByCategory.trigger]: (state) => {
    state.services.loading = true;
    state.services.error = null;
  },
  [fetchServicesByCategory.resolved]: (state, action) => {
    state.services.loading = false;
    state.services.data = action.payload;
  },
  [fetchServicesByCategory.rejected]: (state, action) => {
    state.services.loading = false;
    state.services.error = action.payload;
  },
});

export default serviceReducer;

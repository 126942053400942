import { useCallback, useState } from "react";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import SpButton from "../SpButton/SpButton";
import { message  } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const SpDownloadReceiptButton = ({contentHtmlId}) => {
    const [loading, setLoading] = useState(false);

    const downloadPDF = () => {
        setLoading(true);

        const input = document.getElementById(contentHtmlId);
        html2canvas(input, { scale: 3 })  // Increase the scale to improve quality
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;
    
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
            heightLeft -= pageHeight;
    
            while (heightLeft >= 0) {
              position = heightLeft - imgHeight;
              pdf.addPage();
              pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
              heightLeft -= pageHeight;
            }
    
            pdf.save('download.pdf');

            setLoading(false);
          });
      };

    const handleDownloadReceipt = useCallback(() => {
        //Make an API call to get the receipt from the backend
        downloadPDF();
        //message.loading("Downloading invoice. Please wait...", 10);
    }, []);

    return (<SpButton onClick={!loading && handleDownloadReceipt}>{loading && <LoadingOutlined />} Download Receipt</SpButton>);
}

export default SpDownloadReceiptButton;
import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  UPDATE_TRANSACTION_FORM_DATA,
  TRANSACTION_FORM_DATA_RESET,
  INITIATE_TRANSACTION,
  RESET_TRANSACTION_INIT,
  FETCH_TRANSACTION_DETAILS,
  FETCH_TRANSACTION_LIST,
  SET_PAYMENT_TYPE,
} from '../types';

// Action creator to set the payment type
export const setPaymentType = (paymentType) => ({
  type: SET_PAYMENT_TYPE,
  payload: {
    paymentType,
  },
});

// Action creator to update transaction form data
export const updateTransactionFormData = (data) => ({
  type: UPDATE_TRANSACTION_FORM_DATA,
  payload: data,
});

// Action creator to reset transaction form data
export const resetTransactionFormData = () => ({
  type: TRANSACTION_FORM_DATA_RESET,
});

// Action creator for initiating a transaction with promise action
export const initiateTransaction = createPromiseAction(INITIATE_TRANSACTION);

// Action creator to reset initiate transaction state
export const resetTransactionInitState = () => ({
  type: RESET_TRANSACTION_INIT,
});

// Action creator for fetching transaction details with promise action
export const fetchTransactionDetails = createPromiseAction(FETCH_TRANSACTION_DETAILS);

// Action creator for fetching transaction list with promise action
export const fetchTransactionList = createPromiseAction(FETCH_TRANSACTION_LIST);

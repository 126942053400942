import { call, all, takeEvery } from 'redux-saga/effects';
import { validateAccountRequest } from '../../api/accountValidation'; // API request function
import { validateAccount } from '../actions/accountValidation'; // Promise action
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils';

// Saga to handle account validation
export function* handleValidateAccount(action) {
  try {
    const response = yield call(validateAccountRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action with the data
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action with the error message
  }
}

// Root saga to watch for account validation actions
export default function* accountValidationSaga() {
  yield all([
    takeEvery(validateAccount.trigger, handleValidateAccount), // Watch for the validateAccount action trigger
  ]);
}

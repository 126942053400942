import React from "react";
import Icon from "../../../assets/Icon";

const SuccessModal = ({ title, subtitle }) => {
  return (
    <div className="success-modal">
      <Icon icon="seen-checkmark" height={96} width={96} />
      <div className="centered_text text-content">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
    </div>
  );
};

export default SuccessModal;

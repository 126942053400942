import React from "react";

function PinInput({ value, onChange }) {
  return (
    <div className="pin__input" onClick={() => onChange(value)}>
      {value}
    </div>
  );
}

export default PinInput;

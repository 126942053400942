import { createReducer } from '@reduxjs/toolkit';
import { updateTransactionPin } from '../actions/account'; // Ensure the path and imports are correct

const initialState = {
  txPin: {
    loading: false,
    error: null,
  },
};

const accountReducer = createReducer(initialState, {
  // Trigger action
  [updateTransactionPin.trigger]: (state) => {
    // Ensure txPin exists before modifying
    if (!state.txPin) {
      state.txPin = { loading: false, error: null };
    }
    state.txPin.loading = true;
    state.txPin.error = null;
  },
  
  // Resolved action
  [updateTransactionPin.resolved]: (state, action) => {
    if (!state.txPin) {
      state.txPin = { loading: false, error: null };
    }
    state.txPin.loading = false;
    state.txPin.error = null;
    // Handle any returned data, if applicable
    // state.txPin.data = action.payload;
  },
  
  // Rejected action
  [updateTransactionPin.rejected]: (state, action) => {
    if (!state.txPin) {
      state.txPin = { loading: false, error: null };
    }
    state.txPin.loading = false;
    state.txPin.error = action.payload || 'Failed to update transaction pin';
  },
});

export default accountReducer;

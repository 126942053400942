import { message } from "antd";
import React, { useCallback, useState } from "react";
import SapaInput from "../../../ui/Input/Input";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { login } from './../../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserState } from "../../../redux/actions/user";
import withUserData from "../../../HOC/withUserData";
import { useNavigate } from "react-router-dom";
import { sleep } from "../../../utils/utils";

const GetStarted = ({ onClick, redirectTo = '/' }) => {
  const [number, setNumber] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.auth.login);

  const {
    loading: loginLoading = false,
    //data: loginData,
  } = loginState;

  const handleChange = useCallback((e) => setNumber(e.target.value), []);
  const validate = (value) => {
    const isMatch = /^([0|+[0-9]{1,4})?([7-9][0-1][0-9]{8})$/.test(value);
    return isMatch;
  };

  const handleSubmit = useCallback(() => {
    if (!validate(number)) {
      message.error("Please check that the number is correct!");
      return;
    }

    dispatch(resetUserState());

    dispatch(login({
      phone_number: number,
      isGuest: true,
    })).then(response => {
      message.success(response?.message);
      sleep(500).then(() => {
        onClick(number);
        navigate(redirectTo);
      })
    }).catch(error => {
      //Do nothing
    });
  }, [number, onClick]);

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Get Started</h3>
        <p>Enter your mobile number to get started</p>
      </div>

      <SapaInput
        value={number}
        inputMode="numeric"
        onChange={handleChange}
        autoFocus
      />

      <div className="modal_components__button">
        <SpButton fullWidth={true} loading={loginLoading} onClick={handleSubmit}>Continue</SpButton>
      </div>
    </div>
  );
};

export default withUserData(GetStarted);

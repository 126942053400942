import { Row, Select } from "antd";
import React from "react";
import PropTypes from "prop-types";

import _find from "lodash/find";

function Dropdown({
  label,
  options,
  value,
  defaultValue,
  customClass,
  handleChange,
  containerStyle,
  disabled,
  suffixIcon,
}) {
  const val = _find(options, { value });

  return (
    <div style={containerStyle}>
      <Row>
        <label>{label}</label>
      </Row>
      <Row>
        <Select
          value={val}
          className={`dropdown ${customClass}`}
          onChange={handleChange}
          options={options}
          disabled={disabled}
          style={{ width: "100%" }}
          suffixIcon={suffixIcon}
        />
      </Row>
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string,
  customClass: PropTypes.string,
  handleChange: PropTypes.func,
  containerStyle: PropTypes.object,
};

export default Dropdown;

import SpSummaryCard from "../../sp-components/SpSummaryCard/SpSummaryCard";
import { formatAmount } from "../../helpers/numbers";
import SpButton from "../../sp-components/SpButton/SpButton";
import SpCopy from "../../sp-components/SpCopy/SpCopy";
import { useLocation, useNavigate } from "react-router-dom";
import usePusherNotification from "../../hooks/usePusherNotification";
import withUserData from '../../HOC/withUserData';
import { useEffect, useState } from "react";
import SpLoadingOverlay from "../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import { message } from "antd";
import { getNavPath } from "../../routes/routes.helpers";
import { NAV_ELECTRICITY } from "../../routes/routes.constant";
import { sleep } from "../../utils/utils";

const { Title: CardTitle, Text: CardText } = SpSummaryCard;

const BankTransfer = ({ userData, wallet = {} }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const { userId } = userData;
    const txEventData = usePusherNotification(`app-notifications-${userId}`, 'PAYMENT_NOTIFICATION');
    const txData = txEventData?.data;
    const status = txData?.status?.toLowerCase();

    const {
        amount = 0,
        virtual_account = {},
        transaction_reference: transactionReference,
    } = location?.state || {};

    const {
        bank_name: bankName,
        account_number: accountNumber,
    } = virtual_account;

    const {
        main_balance: walletBalance = 0,
    } = wallet?? {};

    useEffect(() => {
        let isCancelled = false;

        if (!transactionReference) {
            navigate(-1);
            return undefined;
        }

        if (!txData || !userId || userId !== txData?.user_id || transactionReference !== txData?.transaction_reference) {
            return;
        }

        if (status === "success" && !isCancelled) {
            message.success("Your transaction was processed successfully.");
            setIsLoading(true);

            sleep(300).then(() => {
                if (!isCancelled) {
                    setIsLoading(false);
                    sleep(3000).then(() => {
                        if (!isCancelled) {
                            navigate(`${getNavPath(NAV_ELECTRICITY)}/done`, { state: {transaction: txData}, replace: true });
                        }
                    });
                }
            });
        }

        return () => {
            isCancelled = true;
        };
    }, [userId, status, txData, navigate, transactionReference]);

    return (
        <SpLoadingOverlay isLoading={isLoading}>
            <div className="payment__wrapper">
                <h3 className="text-center">Complete payment</h3>
                <p className="text-center" style={{ marginTop: 24 }}>
                    Complete your electricity payment by funding your wallet.
                    Copy and recharge using the details below:
                </p>

                <div className="payment__card" style={{ marginTop: 20 }}>
                    {walletBalance >= 0 && (
                        <div>
                            <CardTitle>Wallet Balance</CardTitle>
                            <CardText>{formatAmount(walletBalance, "₦")}</CardText>
                        </div>
                    )}

                    <div style={{ marginTop: 20 }}>
                        <CardTitle>Amount to Pay</CardTitle>
                        <div style={{ display: "flex", gap: 40 }}>
                            <CardText>{formatAmount(amount, "₦")}</CardText>
                            <SpCopy
                                text={amount.toString()}
                                successMessage="Amount copied!"
                                errorMessage="Failed to copy amount!"
                                label="Copy"
                                useButton={true}
                                size="small"
                                type="outline"
                            >
                                Copy
                            </SpCopy>
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <CardTitle>Account Number</CardTitle>
                        <div style={{ display: "flex", gap: 40 }}>
                            <CardText>{accountNumber}</CardText>
                            <SpCopy
                                text={accountNumber}
                                successMessage="Account number copied!"
                                errorMessage="Failed to copy account number!"
                                label="Copy"
                                useButton={true}
                                size="small"
                                type="outline"
                            >
                                Copy
                            </SpCopy>
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <CardTitle>Bank Name</CardTitle>
                        <CardText>{bankName}</CardText>
                    </div>
                </div>

                <div className="text-center" style={{ marginTop: 20 }}>
                    <SpButton disabled={status === "success"}>I have sent the money</SpButton>
                </div>
            </div>
        </SpLoadingOverlay>
    );
}

export default withUserData(BankTransfer);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SpCardList = ({ items, renderContent, renderFooter, onSelected, defaultSelectedIndex }) => {
  const [activeIndex, setActiveIndex] = useState(defaultSelectedIndex);

  useEffect(() => {
    if (defaultSelectedIndex !== null && typeof onSelected === 'function' && items[defaultSelectedIndex]) {
      onSelected(items[defaultSelectedIndex], defaultSelectedIndex);
    }
  }, [defaultSelectedIndex, items]);

  const handleItemClick = (index) => {
    setActiveIndex(index);

    if (typeof onSelected === 'function') {
      onSelected(items[index], index);
    }
  };

  return (
    <div className="sp-card__list">
      {items.map((item, index) => (
        <div className={`sp-card__item ${index === activeIndex ? 'active' : ''}`} onClick={() => handleItemClick(index)} key={index}>
          {index === activeIndex && <div className="sp-card__checkmark">&#10003;</div>}
          <div>
            {renderContent(item, index === activeIndex, index)}
          </div>
          {renderFooter && renderFooter(item, index === activeIndex, index)}
        </div>
      ))}
    </div>
  );
};

SpCardList.propTypes = {
  items: PropTypes.array.isRequired,
  renderContent: PropTypes.func.isRequired,
  renderFooter: PropTypes.func,
  onSelected: PropTypes.func,
  defaultSelectedIndex: PropTypes.number,
};

SpCardList.defaultProps = {
  defaultSelectedIndex: -1,
};

export default SpCardList;

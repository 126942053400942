import { useEffect, useState } from "react";
import { getAccountDetails } from "../api/user";
import { LOCAL_STARAGE_KEYS } from "../helpers/config";
import { getItem, setItem } from "../helpers/localStorage";
import { getIsGuestUser } from "../redux/selectors/user";
import { shallowEqual, useSelector } from "react-redux";

const useAccountBalance = () => {
  const [accountDetails, setAccountDetails] = useState();
  const [accountBalance, setAccountBalance] = useState(0.0);
  const isGuest = useSelector(getIsGuestUser, shallowEqual);

  useEffect(() => {
    const fetchUserAccountDetails = async () => {
      try {
        const {
          accountDetails: cachedAccountDetails,
          walletBal: cachedWalletBalance,
        } =
          (await getItem({
            key: LOCAL_STARAGE_KEYS.ACCOUNT_DETAILS,
            parseJson: true,
          })) || {};
        // first set cached data while we check for any new updates
        if (cachedAccountDetails) {
          setAccountDetails(cachedAccountDetails);
          setAccountBalance(cachedWalletBalance?.balance);
        }
        const fetchedAccountDetails = await getAccountDetails();
        const { accountDetails, walletBal } =
          fetchedAccountDetails?.data?.data?.message;
        if (
          !cachedAccountDetails ||
          !cachedWalletBalance ||
          cachedAccountDetails?.account_number !==
            accountDetails?.account_number ||
          cachedWalletBalance?.balance !== walletBal?.balance
        ) {
          setAccountDetails(accountDetails);
          setAccountBalance(walletBal?.balance);
          setItem({
            key: LOCAL_STARAGE_KEYS.ACCOUNT_DETAILS,
            value: { accountDetails, walletBal },
            stringify: true,
          });
        }
      } catch (error) {
        console.log(`Error fetching user account: ${error}`);
      }
    };

    if (!isGuest) {
      fetchUserAccountDetails();
    }
  }, [isGuest]);

  return {
    accountDetails,
    accountBalance,
  };
};

export default useAccountBalance;

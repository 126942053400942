export const SHOW_MODAL = "moal/showModal";
export const HIDE_MODAL = "modal/hideModal";
export const SHOULD_SHOW_MODAL = "modal/shouldShowModal";

export const DO_LOGIN = "auth/login";
export const UPDATE_INFO = "auth/updateInfo";

export const SEND_OTP = "auth/sendOTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VERIFY_OTP = "auth/verifyOtp";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";
export const OTP_RESET = "OTP_RESET";


export const VERIFY_CODE = "auth/verifyCode";
export const DO_REGISTER = "auth/register";
export const AUTH_ERRORS = "auth/errors";
export const DO_REGISTER_BUSINESS = "auth/registerBusiness";
export const SET_ACCOUNT_TYPE = "auth/setAccountType";


export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_RESET = 'LOGIN_RESET';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_RESET = 'REGISTER_RESET';

// Fetch User
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

// Update User
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

// Local Update User
export const LOCAL_UPDATE_USER = 'LOCAL_UPDATE_USER';

// Logout
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

// Reset User State
export const RESET_USER_STATE = 'RESET_USER_STATE';

export const SET_CURRENT_BENEFICIARY = "user/currentBeneficiary";
export const SET_SELECTED_RECURRING_PAYMENT = "user/selectedRecurringPayment";

export const SET_TRANSACTION_PIN = "user/setTransactionPin";
export const DO_LOGOUT = "user/logout";

export const UPDATE_TRANSACTION_PIN = "account/updateTransactionPin";

export const SET_PAYMENT_TYPE = "transactions/setPaymentType";

export const FETCH_SERVICE_CATEGORIES = '/services/categories/list';
export const FETCH_SERVICE_CATEGORIES_SUCCESS = 'FETCH_SERVICE_CATEGORIES_SUCCESS';
export const FETCH_SERVICE_CATEGORIES_FAILURE = 'FETCH_SERVICE_CATEGORIES_FAILURE';

export const FETCH_SERVICES_BY_CATEGORY = '/services';
export const FETCH_SERVICES_BY_CATEGORY_SUCCESS = 'FETCH_SERVICES_BY_CATEGORY_SUCCESS';
export const FETCH_SERVICES_BY_CATEGORY_FAILURE = 'FETCH_SERVICES_BY_CATEGORY_FAILURE';


export const UPDATE_TRANSACTION_FORM_DATA = "UPDATE_TRANSACTION_FORM_DATA";
export const TRANSACTION_FORM_DATA_RESET = "TRANSACTION_FORM_DATA_RESET";
export const INITIATE_TRANSACTION = "INITIATE_TRANSACTION";
export const RESET_TRANSACTION_INIT = "RESET_TRANSACTION_INIT";
export const INITIATE_TRANSACTION_SUCCESS = "INITIATE_TRANSACTION_SUCCESS";
export const INITIATE_TRANSACTION_FAILURE = "INITIATE_TRANSACTION_FAILURE";
export const FETCH_TRANSACTION_DETAILS = "FETCH_TRANSACTION_DETAILS";
export const FETCH_TRANSACTION_DETAILS_SUCCESS = "FETCH_TRANSACTION_DETAILS_SUCCESS";
export const FETCH_TRANSACTION_DETAILS_FAILURE = "FETCH_TRANSACTION_DETAILS_FAILURE";
export const FETCH_TRANSACTION_LIST = "FETCH_TRANSACTION_LIST";
export const FETCH_TRANSACTION_LIST_SUCCESS = "FETCH_TRANSACTION_LIST_SUCCESS";
export const FETCH_TRANSACTION_LIST_FAILURE = "FETCH_TRANSACTION_LIST_FAILURE";


export const VALIDATE_ACCOUNT = 'VALIDATE_ACCOUNT';
export const VALIDATE_ACCOUNT_SUCCESS = 'VALIDATE_ACCOUNT_SUCCESS';
export const VALIDATE_ACCOUNT_FAILURE = 'VALIDATE_ACCOUNT_FAILURE';
export const VALIDATE_ACCOUNT_RESET = 'VALIDATE_ACCOUNT_RESET';




import axios from "axios";
import { stringify } from "qs";
import { message } from "antd";
import { getAccessToken, redirectAfterDelay, setAccessToken } from "../utils/utils";

// Set up Axios instance with defaults
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Set the base URL when creating the instance
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: "brackets" }),
  },
});

// Intercept request to add Authorization header
instance.interceptors.request.use(
  async function (requestConfig) {
    const accessToken = getAccessToken();

    // Ensure headers is an object before setting Authorization
    requestConfig.headers = requestConfig.headers || {};

    if (accessToken) {
      requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }

    return requestConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Intercept response to handle errors
instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      message.error(error.response?.data?.message || "Unauthorized");
      setAccessToken(); // You might want to clear the token or refresh it here
      redirectAfterDelay(); // Redirect the user after clearing the token
    }
    return Promise.reject(error);
  }
);

// Utility to get error message from API response
export const getApiErrorMessage = ({ response }) =>
  response?.data?.data?.message?.message || response?.data?.data?.message;

export default instance;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Dropdown, Layout, message } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { MenuOutlined } from "@ant-design/icons";
import SapaLogo from "../../assets/logo/Logo.png";
import SapaButton from "../../ui/Button/Button";
import { getModalVisible } from "../../redux/selectors/modal";
import Dashboard from "../../pages/Dashboard/Dashboard";
import _upperFirst from "lodash/upperFirst";

import SapaDrawer from "./Layout.Drawer";
import {
  NAV_LOGIN,
  NAV_SIGN_UP,
  NAV_SIGN_UP_BASE,
} from "../../routes/routes.constant";
import { getNavPath } from "../../routes/routes.helpers";

import { logout } from "../../redux/actions/user";
import SapaFooter from "../Footer/Footer";
import Icon from "../../assets/Icon";
import withUserData from './../../HOC/withUserData';
import { sleep } from "../../utils/utils";
import useIdleTimer from "../../hooks/useIdleTimer";
import { refreshToken } from "../../redux/actions/auth";



const AppLayout = ({ children, hasSideMenu, rootClass = '', userData, virtualAccounts, isLoggedIn, loggedInAsGuest, isGuest = true, isProtected = false }) => {
  const dispatch = useDispatch();
  const isModalVisible = useSelector(getModalVisible, shallowEqual);
  const [logoutInitiated, setInitiateLogout] = useState(false);

  const {
    first_name: firstName,
    last_name: lastName,

  } = userData;

  const [visible, setVisible] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = useCallback(
    () => setOpenDrawer(!openDrawer),
    [openDrawer]
  );

  let navigate = useNavigate();

  const phoneScreenWidth = 440;
  const isPhoneScreen = window.innerWidth < phoneScreenWidth;

  const handleLogout = useCallback(() => {
    setInitiateLogout(true);
    message.loading("Logging out...")
    
    sleep(1000).then(() => {
      dispatch(logout());
    })
  }, [dispatch]);
  

  const navigateToHome = useCallback(() => {
    window.location.href = "/";
  }, []);

  useEffect(() => {
    if (isProtected && !isLoggedIn) {
      navigate('/');
    }
  }, [isProtected, isLoggedIn]);

  const userInitials = useMemo(
    () =>
      `${_upperFirst(firstName?.substring(0, 1))}${_upperFirst(
        lastName?.substring(0, 1)
      )}`,
    [firstName, lastName]
  );

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const onIdle = useCallback(() => {
    if (isLoggedIn) {
      dispatch(refreshToken());
    }
  }, [isLoggedIn, dispatch]);

  useIdleTimer(onIdle, 30000);

  const items = [
    {
      key: "1",
      icon: <Icon icon="calling" width={13} height={13} />,
      label: (
        <a target="_blank" rel="noopener noreferrer" href="tel:+2348139069047">
          {!isPhoneScreen ? "+234 8139069047" : ""}
        </a>
      ),
    },
    {
      key: "2",
      icon: <Icon icon="email-open" width={16} height={16} />,
      label: (
        <a href="mailto:sales@trysapa.com">
          {!isPhoneScreen ? "sales@trysapa.com" : ""}
        </a>
      ),
    },
    {
      key: "3",
      icon: <Icon icon="message-bubble" width={16} height={16} />,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://trysapa.tawk.help/"
        >
          {!isPhoneScreen ? "Chat with our Customer Care" : ""}
        </a>
      ),
    },
    {
      key: "4",
      icon: <Icon icon="message-question" width={16} height={16} />,
      label: (
        <Link onClick={handleButtonClick} to="/about-us">
          {!isPhoneScreen ? "FAQS" : ""}
        </Link>
      ),
    },
  ];

  function handleVisibleChange(visible) {
    setVisible(visible);
  }

  useEffect(() => {
    if (!isLoggedIn && logoutInitiated) {
      message.success("Logged out successfully!");
      setInitiateLogout(false);
    }
  }, [isLoggedIn, logoutInitiated])

  return (
    <Layout className={`container ${rootClass}`}>
      <SapaDrawer showDrawer={openDrawer} onClose={toggleDrawer} />
      <Layout className={`layout ${isModalVisible ? "blur" : ""}`}>
        <Header className="layout__header">
          <div className="layout__nav">
            <img
              className="layout__logo"
              src={SapaLogo}
              alt=""
              onClick={navigateToHome}
            />
            {(!isLoggedIn || loggedInAsGuest) ? (
              <div className="layout__flex_end">

                <div className="layout__need_help" onClick={handleButtonClick}>
                  {isPhoneScreen ? (
                    <Icon icon="question-mark-circle" width={16} height={16} />
                  ) : (
                    <>
                      Need help
                      <Icon
                        icon="question-mark-circle"
                        width={16}
                        height={16}
                      />{" "}
                    </>
                  )}
                </div>
                {visible ? (
                  <Dropdown
                    placement={isPhoneScreen ? "bottomCenter" : "bottom"}
                    menu={{ items }}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["click"]}
                    overlayClassName="layout__need-help_menu"
                  >
                    <span></span>
                  </Dropdown>
                ) : (
                  ""
                )}
                <span
                  onClick={() => navigate(getNavPath(NAV_LOGIN))}
                  className="layout__login_text"
                >
                  Login
                </span>
                <SapaButton
                  onClick={() => navigate(getNavPath(NAV_SIGN_UP_BASE))}
                  label="Sign Up"
                  customClass="layout__button"
                  shape="round"
                  size="sm"
                  buttonType="primary"
                />
              </div>
            ) : (
              <div className="layout__flex_end">
                <div className="layout__need_help" onClick={handleButtonClick}>
                  {isPhoneScreen ? (
                    <Icon icon="question-mark-circle" width={16} height={16} />
                  ) : (
                    <>
                      Need help
                      <Icon
                        icon="question-mark-circle"
                        width={16}
                        height={16}
                      />{" "}
                    </>
                  )}
                </div>
                {visible ? (
                  <Dropdown
                    menu={{ items }}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["click"]}
                  >
                    <span></span>
                  </Dropdown>
                ) : (
                  ""
                )}
                <SapaButton
                  label="Logout"
                  customClass="layout__button"
                  shape="round"
                  size="sm"
                  buttonType="cancel-light"
                  onClick={handleLogout}
                />
              </div>
            )}
          </div>

          <div className="layout__nav_mobile">
            {!isGuest && (
              <MenuOutlined onClick={toggleDrawer} style={{ fontSize: 20 }} />
            )}
            <img
              className="layout__logo"
              src={SapaLogo}
              alt=""
              onClick={navigateToHome}
            />
            {isGuest ? (
              <div className="layout__flex_end">
                <div className="layout__need_help" onClick={handleButtonClick}>
                  {isPhoneScreen ? (
                    <Icon icon="question-mark-circle" width={16} height={16} />
                  ) : (
                    <>
                      Need help
                      <Icon
                        icon="question-mark-circle"
                        width={16}
                        height={16}
                      />{" "}
                    </>
                  )}
                </div>
                {visible ? (
                  <Dropdown
                    placement={isPhoneScreen ? "bottomCenter" : "bottom"}
                    menu={{ items }}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["click"]}
                    overlayClassName="layout__need-help_menu"
                  >
                    <span></span>
                  </Dropdown>
                ) : (
                  ""
                )}
                <span
                  onClick={() => navigate(getNavPath(NAV_LOGIN))}
                  className="layout__login_text"
                >
                  Login
                </span>
                <SapaButton
                  onClick={() => navigate(getNavPath(NAV_SIGN_UP))}
                  label="Sign Up"
                  customClass="layout__button"
                  shape="round"
                  buttonType="primary"
                />
              </div>
            ) : (
              <div className="layout__flex_end">
                <div className="layout__need_help" onClick={handleButtonClick}>
                  {isPhoneScreen ? (
                    <Icon icon="question-mark-circle" width={16} height={16} />
                  ) : (
                    <>
                      Need help
                      <Icon
                        icon="question-mark-circle"
                        width={16}
                        height={16}
                      />{" "}
                    </>
                  )}
                </div>
                {visible ? (
                  <Dropdown
                    placement={isPhoneScreen ? "bottomCenter" : "bottom"}
                    menu={{ items }}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["click"]}
                    overlayClassName="layout__need-help_menu"
                  >
                    <span></span>
                  </Dropdown>
                ) : (
                  ""
                )}
                <div className="avatar_text">
                  <span>{userInitials}</span>
                </div>
              </div>
            )}
          </div>
        </Header>
        <main>
          {hasSideMenu ? (
            <div className="app-layout">
              <Dashboard>{children}</Dashboard>
            </div>
          ) : (
            <Content className="dashboard">
              <Layout className="dashboard__layout main__layout">
                <Content className="dashboard__content layout__child">
                  {children}
                </Content>
              </Layout>
            </Content>
          )}
        </main>
        <SapaFooter />
      </Layout>
    </Layout>
  );
};

export default withUserData(AppLayout);

import _compact from "lodash/compact";

function validateKey(key) {
  if (typeof key !== "string") {
    const msg = `Key(${JSON.stringify(key)}) must be string`;
    console.error(msg);
    throw new Error(msg);
  }
}

function processValue(key, value, stringify) {
  const isStringValue = typeof value === "string";
  if (!stringify && !isStringValue) {
    const msg = `Key(${key}). Value must be string or use "setItem(key, value, true)" to stringify content`;
    console.error(msg);
    throw new Error(msg);
  }
  return isStringValue ? value : JSON.stringify(value);
}

export async function getItem({ key, parseJson = false }) {
  validateKey(key);
  try {
    const value = await localStorage.getItem(key);
    if (value && parseJson) {
      return JSON.parse(value);
    }
    return value;
  } catch (error) {
    console.error(`[localStorage.getItem ${key}] ${error.toString()}`);
    return null;
  }
}

export async function getItems({ keys, parseJson = false }) {
  try {
    const values = await localStorage.multiGet(_compact(keys));
    if (values) {
      const data = {};
      values.forEach(([key, val]) => {
        data[key] = parseJson ? JSON.parse(val) : val;
      });
      return data;
    }
  } catch (error) {
    console.error(
      `[localStorage.getItems ${JSON.stringify(keys)}] ${error.toString()}`
    );
  }
  return {};
}

export async function setItem({ key, value, stringify = false }) {
  validateKey(key);
  const _value = processValue(key, value, stringify);
  try {
    // Needs to await in order to catch any possible error
    await localStorage.setItem(key, _value);
    return true;
  } catch (error) {
    console.error(`[localStorage.setItem ${key}] ${error.toString()}`);
    return null;
  }
}

// Receives an object of Key/Value items, that needs to processed into separate items in storage
export async function setItems({ data, stringify = false }) {
  const keys = Object.keys(data);
  const dataArray = keys.map((key) => {
    validateKey(key);
    const value = data[key];
    const _value = processValue(key, value, stringify);
    return [key, _value];
  });
  try {
    await localStorage.multiSet(dataArray);
    return true;
  } catch (error) {
    console.error(
      `[localStorage.setItems ${JSON.stringify(data)}] ${error.toString()}`
    );
    return null;
  }
}

export async function removeItem(key) {
  try {
    validateKey(key);
    // Needs to await in order to catch any possible error
    await localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error(`[localStorage.removeItem ${key}] ${error.toString()}`);
    return null;
  }
}

export async function multiRemove(keys) {
  try {
    // Needs to await in order to catch any possible error
    keys.map(async (key) => {
      validateKey(key);
      await localStorage.removeItem(key);
      return true;
    });
  } catch (error) {
    console.error(`[localStorage.multiRemove ${keys}] ${error.toString()}`);
    return null;
  }
}

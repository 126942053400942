import { Outlet, useLocation } from "react-router-dom";
import { pathToIndex, redirectAfterDelay } from "../../utils/utils";
import SpSteps from "../../sp-components/SpSteps/SpSteps";
import withUserData from "../../HOC/withUserData";


//Order of precedence
const tabs = [
  "SIGNUP", "VERIFY", "PIN"
];

const SignupIndex = ({isLoggedAsGuest = true, isLoggedIn}) => {
  const location = useLocation();
  const pathName = location.pathname;

  const stepItems = [
    { title: "SIGN UP", icon: <></> },
    { title: "VERIFY OTP", icon: <></> },
    { title: "SET PIN", icon: <></> }
  ];

  if (!isLoggedAsGuest && isLoggedIn) {
    redirectAfterDelay('/', 0);
  }

  return (
    <div className="bg-white">
      <div style={{
        maxWidth: "537px",
        margin: "44px auto"
      }}>
        <SpSteps items={stepItems} current={pathToIndex(pathName, tabs)} />
      </div>
      <Outlet />
    </div>
  );
};

export default withUserData(SignupIndex);

import React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";

// const ServiceTile = ({ label, icon, bgColor, url, onClick }) => {
//   return (
//     <div
//       className="service_tile"
//       style={{ background: bgColor }}
//       onClick={onClick}
//     >
//       <img style={{ width: "50%" }} src={icon} alt="" />
//       <span className="service_tile__text">{label}</span>
//     </div>
//   );
// };

const ServiceTile = ({ label, icon, bgColor, url, onClick, opacity }) => {
  return (
    <Col span={8}>
      {" "}
      <div
        className="service_tile"
        style={{ background: bgColor, opacity: opacity }}
        onClick={() => onClick(label, url)}
      >
        <img style={{ width: "45px" }} src={icon} alt="" />
        <span className="service_tile__text">{label}</span>
      </div>
    </Col>
  );
};

ServiceTile.propTypes = {
  label: PropTypes.string,
};

export default ServiceTile;

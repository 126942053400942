import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Layout, Menu } from "antd";
import { MENU_KEYS, dashboardMenus } from "./Dashboard.helper";
import DashboardInfo from "./Dashboard.info";
import { useLocation, useNavigate } from "react-router-dom";
import { getNavPath } from "../../routes/routes.helpers";
import useAccountBalance from "../../hooks/useAccountBalance";
import withUserData from "../../HOC/withUserData";
const { Content, Sider } = Layout;

const Dashboard = ({ children, account, userData = {}, loggedInAsGuest = true}) => {
  const { firstName } = userData;
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  
  const { electricity } = MENU_KEYS;
  const [activeKey, setActiveKey] = useState(electricity);

  useEffect(() => {
    const pathParts = pathName.split("/");
    const active = pathParts[1]?? activeKey;

    setActiveKey(active.toLowerCase());
    
  }, [pathName]);

  const getMenuItems = useMemo(() => {
    if (activeKey) {
      return dashboardMenus(activeKey);
    }
    return [];
  }, [activeKey]);

  const handleClick = useCallback(
    (e) => {
      setActiveKey(e.key);
    },
    [navigate, location, loggedInAsGuest]
  );

  useEffect(() => {
    const whiteListedKeys = ['transactions', 'account'];

    if (loggedInAsGuest && whiteListedKeys.includes(activeKey)) {
      navigate("/login");
      return;
    } 
    
    const activeBasePathName = "/" + activeKey;

    if (pathName.startsWith(activeBasePathName)) {
      navigate(pathName);
    }
    else {
      navigate(getNavPath(activeKey));
    }
  }, [loggedInAsGuest, activeKey]);

  const { accountDetails, accountBalance } = useAccountBalance();
  const localAccount = account || accountDetails;

  return (
    <Content className="dashboard">
      <Layout className="dashboard__layout">
        <Sider className="dashboard__side hide-side-menu">
          <div className="card">
            <DashboardInfo
              balance={accountBalance}
              username={firstName}
              loggedInAsGuest={loggedInAsGuest}
              bankName={localAccount?.bank_name}
              accountNumber={localAccount?.account_number}
            />
          </div>
          <br />
          <Menu
            mode="inline"
            className="dashboard__menu"
            selectedKeys={[activeKey]}
          >
            {getMenuItems.map(({ key, icon, label }) => (
              <Menu.Item
                className="dashboard__menu_item"
                icon={icon}
                key={key}
                onClick={handleClick}
              >
                <Fragment>
                  {label}
                  {activeKey === key && (
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  )}
                </Fragment>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Content className="dashboard__content">{children}</Content>
      </Layout>
    </Content>
  );
};


export default withUserData(Dashboard);

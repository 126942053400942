import { createReducer } from '@reduxjs/toolkit';
import { sendOtp, verifyOtp } from '../actions/otpVerification'; // Assuming you renamed and imported these actions
import { OTP_RESET } from '../types';

const initialState = {
  verify: {
    loading: false,
    error: null,
    data: null,
  },
  send: {
    loading: false,
    error: null,
    data: null,
  },
};

const otpVerificationReducer = createReducer(initialState, {
  // Handle sending OTP
  [sendOtp.trigger]: (state) => {
    state.send.loading = true;
    state.send.error = null;
    state.send.data = null;
    state.verify.error = null;
  },
  [sendOtp.resolved]: (state, action) => {
    state.send.loading = false;
    state.send.data = action.payload;
  },
  [sendOtp.rejected]: (state, action) => {
    state.send.loading = false;
    state.send.error = action.payload;
  },
  
  // Handle verifying OTP
  [verifyOtp.trigger]: (state) => {
    state.verify.loading = true;
    state.verify.error = null;
    state.verify.data = null;
  },
  [verifyOtp.resolved]: (state, action) => {
    state.verify.loading = false;
    state.verify.data = action.payload;
  },
  [verifyOtp.rejected]: (state, action) => {
    state.verify.loading = false;
    state.verify.error = action.payload;
  },
  
  // Handle resetting OTP state
  [OTP_RESET]: () => initialState,
});

export default otpVerificationReducer;

import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';

// Initialize Pusher with your credentials
const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  wsHost: process.env.REACT_APP_PUSHER_HOST,
  wsPort: parseInt(process.env.REACT_APP_PUSHER_PORT, 10),
  wssPort: parseInt(process.env.REACT_APP_PUSHER_PORT, 10),
  forceTLS: process.env.REACT_APP_PUSHER_SCHEME === 'https',
  disableStats: true,
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || 'mt1',
  enabledTransports: ['ws', 'wss'],
});

const usePusherNotification = (channelName, eventType) => {
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    try {
      // Subscribe to the channel
      const channel = pusher.subscribe(channelName);
      
      // Bind to the event
      channel.bind(eventType, function (data) {
        setEventData(data);  // Set the received data to state
      });

      // Cleanup on component unmount
      return () => {
        channel.unbind(eventType);
        pusher.unsubscribe(channelName);
        console.log('Unsubscribed from channel:', channelName);
      };
    } catch (error) {
      console.error('Pusher initialization error:', error);
    }
  }, [channelName, eventType]);

  return eventData;
};

export default usePusherNotification;

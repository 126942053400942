import _isEmpty from "lodash/isEmpty";
import vector from "../../../src/assets/svgs/empty-state.svg";

const SERVICE_FEE = 100;

export const ERR_MAPS = {
  REQUIRED: "Field cannot be empty",
  NUMBER_SHORT: "10 digits required",
  POSTPAID_NUMBER_SHORT: "9 digits required",
  NOT_VERIFIED: "Incorrect meter number. Re-check details",
  INVALID_DISCO: "Please select a service",
};

export const getDiscoError = (disco) => {
  if (_isEmpty(disco)) return "INVALID_DISCO";
  return null;
};

export const getAmountError = (amount) => {
  if (_isEmpty(amount)) return "REQUIRED";
  return null;
};

export const getMeterError = (number, verified, accountType) => {
  if (_isEmpty(number)) return "REQUIRED";
  if (accountType === "postpaid") {
    if (number.length < 9) {
      return "POSTPAID_NUMBER_SHORT";
    }
  } else if (accountType === "prepaid") {
    if (number.length < 10) {
      return "NUMBER_SHORT";
    }
  }
  // if (number.length < 10) return "NUMBER_SHORT";
  if (!verified) return "NOT_VERIFIED";
  return null;
};

export const getTotalAmount = (amt) => parseInt(amt) + SERVICE_FEE;

export const EmptyState = () => {
  return (
    <div className="empty-state">
      <img src={vector} alt="empty state vector" />
      <div className="empty-state__inner">
        <h3>Empty state discovered</h3>
        <p>
          Hey there, there’s no data to display here at the moment. Please check
          in later!
        </p>
      </div>
    </div>
  );
};


import { FETCH_SERVICE_CATEGORIES } from './../redux/types';

export const LOCAL_ITEMS_TO_DELETE = {
  SERVICE_INFO: "serviceInfo",
  ACCESS_TOKEN: "auth/accessToken",
  TRANSACTION_PIN: "user/transactionPin",
  SERVICE_INPUT_DETAILS: "service/inputDetails",
  SIGNUP_STEPS: "auth/signupSteps",
  ACCOUNT_DETAILS: "auth/accountDetails",
};

export const LOCAL_STARAGE_KEYS = {
  ...LOCAL_ITEMS_TO_DELETE,
  CHOSEN_SERVER: "app/chosenServer",
  FETCH_SERVICE_CATEGORIES
};

const STORE_PERSIST_KEY = "persist:root";
export const dataToRemove = [
  ...Object.keys(LOCAL_STARAGE_KEYS),
  STORE_PERSIST_KEY,
];

export const isMobileDevice = () => {
  const regExp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regExp.test(navigator.userAgent);
  return isMobileDevice;
};

export const SERVER_TYPES = {
  PRODUCTION: "production",
  STAGING: "staging",
};

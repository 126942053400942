import React, { useCallback } from "react";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";
import SapaLogo from "../../assets/logo/Logo.png";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getModalVisible } from "../../redux/selectors/modal";
import { useNavigate } from "react-router-dom";
import SapaFooter from "../Footer/Footer";
import { resetOtpState } from "../../redux/actions/otpVerification";

const AppLayout = ({ children, hasSideMenu }) => {
  const isModalVisible = useSelector(getModalVisible, shallowEqual);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleClick = () => {
    //dispatch(resetOtpState());
    navigate(-1)
  }

  return (
    <Layout className={`blank-layout ${isModalVisible ? "blur" : ""}`}>
      <Content className="blank-layout__wrapper">
        <div className="blank-layout__header">
          <div className="back" onClick={handleClick}>
            <div className="back-icon">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <span>Go Back</span>
          </div>
          <div className="img__wrapper" onClick={() => navigate("/")}>
            <img className="layout__logo" src={SapaLogo} alt="" />
          </div>
          {/* <div className="blank-layout__header-empty"></div> */}
        </div>
        <Content className="dashboard__content layout__child">
          {children}
        </Content>
      </Content>
      <SapaFooter />
    </Layout>
  );
};

export default AppLayout;

import apiClient from "../api/apiClient";
import { FETCH_SERVICES_BY_CATEGORY, FETCH_SERVICE_CATEGORIES } from "../redux/types";

export const fetchServiceCategoriesRequest = () =>
  apiClient({
    method: "GET",
    url: FETCH_SERVICE_CATEGORIES,
  });

export const fetchServicesByCategoryRequest = (data) =>
  apiClient({
    method: "POST",
    url: FETCH_SERVICES_BY_CATEGORY,
    data
  });


export const getDiscosRequest = () =>
  apiClient({
    method: "GET",
    url: "/user/utility/electricity/getDiscos",
  });

export const verifyMeterNumber = (data) =>
  apiClient({
    method: "POST",
    url: "/user/utility/electricity/verify",
    data,
  });

export const buyPower = (data) =>
  apiClient({
    method: "POST",
    url: "/user/utility/electricity/buyPower",
    data,
  });

export const getGuestDiscosRequest = () =>
  apiClient({
    method: "GET",
    url: "/guest/utility/electricity/getDiscos",
  });

export const verifyGuestMeterNumber = (data) =>
  apiClient({
    method: "POST",
    url: "/guest/utility/electricity/verify",
    data,
  });

export const buyGuestPower = (data) =>
  apiClient({
    method: "POST",
    url: "/guest/utility/electricity/buyPower",
    data,
  });

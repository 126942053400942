import { Input, Spin } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const SpInput = ({ onChange, numericOnly, readOnly, isLoading, className, value, ...rest }) => {
  const handleKeyPress = (event) => {
    if (numericOnly) {
      // Check if the pressed key is not a number (0-9) and prevent default
      if (!/^\d$/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  const handlePaste = (event) => {
    if (numericOnly) {
      // Prevent pasting non-numeric content
      const pastedText = event.clipboardData.getData('text/plain');
      if (!/^\d+$/.test(pastedText)) {
        event.preventDefault();
      }
    }
  };

  return (
    <Input
      readOnly={readOnly}
      className={`sp-input ${className || ''}`}
      onKeyPress={handleKeyPress}
      onPaste={handlePaste}
      onChange={onChange}
      value={value}
      suffix={isLoading && <Spin className='sp-input__spin' indicator={<LoadingOutlined spin />} />}
      {...rest}
    />
  );
};

SpInput.propTypes = {
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
  allowClear: PropTypes.bool,
  bordered: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  prefix: PropTypes.node,
  size: PropTypes.oneOf(['large', 'middle', 'small']),
  suffix: PropTypes.node,
  type: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  numericOnly: PropTypes.bool,
  readOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SpInput.defaultProps = {
  isLoading: false,
}

export default SpInput;

import "./styles/base.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import configureStore from "./redux/configureStore";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider, message } from "antd";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import MainApp from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const THEME_CONFIG = {
  hashed: false,
  //   token: {
  //     fontFamily: 'Roboto',
  // },
};

/**<React.StrictMode>**/
const { store, persistor } = configureStore();

message.config({
  maxCount: 1,
});

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={THEME_CONFIG}>
        <MainApp />
      </ConfigProvider>
    </PersistGate>
  </Provider>
);

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import apiClient from "./apiClient";

export const updateTransactionPinRequest = (data) => {
  const {pin, ...restData} = data || {};

  return apiClient({
    method: "POST",
    url: "/account/transaction-pin",
    data: restData,
    headers: {
      pin
    }
  });
}


  
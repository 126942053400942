import { HIDE_MODAL, SHOULD_SHOW_MODAL, SHOW_MODAL } from "../types";

export function showModal(payload) {
  return {
    type: SHOW_MODAL,
    payload,
  };
}

export function shouldShowModal() {
  return {
    type: SHOULD_SHOW_MODAL,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

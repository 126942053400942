import React, { useCallback, useState } from "react";
import SapaInput from "../../../../ui/Input/Input";
import SapaButton from "../../../../ui/Button/Button";
import { editBeneficiary } from "../../../../api/user";
import { message } from "antd";
import { hideModal } from "../../../../redux/actions/modal";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentBeneficiary } from "../../../../redux/selectors/user";

const EditBeneficiary = ({ refetch }) => {
  const currentBeneficiary = useSelector(getCurrentBeneficiary);

  const [beneficiaryName, setBenefeciaryName] = useState(
    currentBeneficiary?.name || ""
  );
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = useCallback((e) => {
    setBenefeciaryName(e.target.value);
  }, []);

  //  const validate = (value) => {
  //    const isMatch = /^([0|+[0-9]{1,4})?([7-9][0-1][0-9]{8})$/.test(value);
  //    return isMatch;
  //  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await editBeneficiary(currentBeneficiary?.id, {
        beneficiaryName: beneficiaryName,
      });

      if (response.status === 200) {
        refetch(Date.now());
        setLoading(false);
        message.success("Beneficiary Name Edited!!");
        setTimeout(() => {
          dispatch(hideModal());
        }, 100);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Something went wrong");
    }
  };

  return (
    <div className="modal_components">
      <div className="centered_text">
        <h3>Edit Beneficiary</h3>
        <p>Enter your preferred beneficiary name</p>
      </div>
      <div>
        <label>Beneficiary name</label>
        <SapaInput
          value={beneficiaryName}
          inputMode="text"
          onChange={handleChange}
          autoFocus
        />
      </div>

      <div className="modal_components__button">
        <SapaButton
          label="Save changes"
          onClick={handleSubmit}
          customClass="full-width"
          loading={loading}
        />
      </div>
    </div>
  );
};

export default EditBeneficiary;

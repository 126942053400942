const transactionsIcon = ({ fill = "#2C264B" }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3098 6.01052C14.3098 4.18812 13.137 2.96252 11.393 2.96252H4.99537C3.24977 2.96252 2.07697 4.18812 2.07697 6.01052C2.07697 6.0768 2.13069 6.13053 2.19697 6.13053H14.1898C14.256 6.13053 14.3098 6.0768 14.3098 6.01052Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5277 8.20566C17.1301 7.53846 16.4725 7.08966 15.6253 6.90806C15.4924 6.87926 15.3669 6.9805 15.3669 7.11645V10.3857C15.3669 12.6513 13.8381 14.2337 11.6477 14.2337H5.68827C5.50511 14.2337 5.39241 14.4295 5.5044 14.5745C5.90038 15.087 6.48155 15.4597 7.21405 15.6177L13.4685 16.9593C13.7109 17.0113 13.9477 17.0369 14.1765 17.0369C15.5517 17.0361 16.6301 16.1193 16.9581 14.5897L17.8365 10.4993C18.0189 9.64489 17.9125 8.85209 17.5277 8.20566Z"
      fill={fill}
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3098 10.1953V7.57124C14.3098 7.43869 14.2023 7.33124 14.0698 7.33124H2.31697C2.18442 7.33124 2.07697 7.43869 2.07697 7.57124V10.1953C2.07697 12.0177 3.24977 13.2433 4.99537 13.2433H11.3922C13.137 13.2433 14.3098 12.0177 14.3098 10.1953Z"
      fill={fill}
    />
  </svg>
);

export default transactionsIcon;

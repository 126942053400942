import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  LOGIN,
  REGISTER,
  REFRESH_TOKEN,
  LOGIN_RESET,
  REGISTER_RESET,
} from '../types';

// Login Actions
export const login = createPromiseAction(LOGIN);

// Register Actions
export const register = createPromiseAction(REGISTER);

// Refresh Token Actions
export const refreshToken = createPromiseAction(REFRESH_TOKEN);

// Action creator to reset login state
export const resetLoginState = () => ({
  type: LOGIN_RESET,
});

// Action creator to reset register state
export const resetRegisterState = () => ({
  type: REGISTER_RESET,
});

import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Chevron from "../../assets/svgs/svg-icons/Chevron";
import ElectricityIcon from "../../assets/svgs/menu-icons/electricity";
import InternetIcon from "../../assets/svgs/menu-icons/internet";
import TransferIcon from "../../assets/svgs/menu-icons/transfer";
import CableIcon from "../../assets/svgs/menu-icons/cable";
import AirtimeIcon from "../../assets/svgs/menu-icons/airtime";
import ExamsIcon from "../../assets/svgs/menu-icons/exams";
import BillReceipt from "../../components/BillReceipt/BillReceipt";
import SapaButton from "../../ui/Button/Button";
import { Dropdown, Menu, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { formatDate } from "../../helpers/date";
import { EmptyState } from "./heplers";
import { formatToken, getServiceLogo } from "./Services.helpers";
import { formatAmount } from "../../helpers/numbers";
import { getUserTransactions } from "../../api/user";

const Transactions = () => {
  const [showReceipt, setShowReceipt] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [allTransactions, setAllTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState(6);
  const [serviceData, setServiceData] = useState();
  const [meterData, setMeterData] = useState();
  const [fetching, setFetching] = useState(true);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({
    value: "all",
    label: "All",
  });

  const { value: filterValue, label } = filter;

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const menuItems = [
    { value: "all", label: "All" },
    { value: "successful", label: "Successful" },
    { value: "failed", label: "Failed" },
    { value: "pending", label: "Pending" },
  ];

  const menu = (
    <Menu
      onClick={(e) => {
        const selectedItem = menuItems.find((item) => item.value === e.key);
        setFilter(selectedItem);
        setVisible(!visible);
      }}
    >
      {menuItems.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  function handleVisibleChange(visible) {
    setVisible(visible);
  }

  const {
    creditToken,
    service: transactionType,
    type: accountType,
    value,
  } = serviceData || {};

  const {
    customerName,
    customerAddress,
    disco,
    type: meterType,
  } = meterData || {};

  useEffect(() => {
    const fetchUserTransactions = async () => {
      try {
        const response = await getUserTransactions();
        const transactions = response?.data?.data?.data?? [];
        setAllTransactions(transactions);
        setFetching(false);
      } catch (error) {
        message.error(error?.message || "error fetching transactions");
        setFetching(false);
      }
    };
    fetchUserTransactions();
  }, []);

  const filteredTransactions = useMemo(() => {
    if (filterValue === "all") {
      return allTransactions;
    } else {
      return allTransactions?.filter(
        (transaction) => transaction.status === filterValue
      );
    }
  }, [allTransactions, filterValue]);


  /* const getTransactionIcon = (utilityType) => {
    switch (utilityType) {
      case "electricity":
        return <ElectricityIcon className="transaction-type" />;
      case "internet":
        return <InternetIcon className="transaction-type" />;
      case "transfer":
        return <TransferIcon className="transaction-type" />;
      case "cable":
        return <CableIcon className="transaction-type" />;
      case "airtime":
        return <AirtimeIcon className="transaction-type" />;
      case "exams":
        return <ExamsIcon className="transaction-type" />;
      default:
        return null;
    }
  }; */

  const groupTransactionsByDate = () => {
    const groupedTransactions = {};
    for (const transaction of filteredTransactions.slice(
      0,
      displayedTransactions
    )) {
      const date = transaction.created_at.split("T")[0];
      if (groupedTransactions[date]) {
        groupedTransactions[date].push(transaction);
      } else {
        groupedTransactions[date] = [transaction];
      }
    }
    return groupedTransactions;
  };

  const groupedTransactions = groupTransactionsByDate();

  const handleChevronClick = (transaction) => {
    setSelectedTransaction(transaction);
    setServiceData(transaction?.powerRecordId);
    setMeterData(transaction?.userMeterId);
    setShowReceipt(true);
  };

  const handleLoadMore = () => {
    if (displayedTransactions + 6 <= filteredTransactions?.length) {
      setDisplayedTransactions((prevCount) => prevCount + 6);
    } else if (displayedTransactions < filteredTransactions?.length) {
      setDisplayedTransactions(filteredTransactions?.length);
    } else {
      setDisplayedTransactions(6);
    }
  };

  const loader = useCallback(() => {
    const antIcon = <LoadingOutlined className="loader-icon" spin />;
    return (
      <div className="justify-content-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }, []);

  return (
    <div className="services__transactions_container">
      {fetching ? (
        loader()
      ) : (
        <>
          {showReceipt ? (
            <div>
              <h3>{selectedTransaction.utilityType}</h3>
              <BillReceipt
                account={selectedTransaction.account}
                name={customerName}
                address={customerAddress}
                token={formatToken(creditToken, accountType)}
                date={selectedTransaction.created_at}
                reference={selectedTransaction.tx_ref}
                status={selectedTransaction.status}
                serviceType={meterType}
                logo={getServiceLogo(disco)}
                serviceAmount={formatAmount(selectedTransaction.amount)}
                transactionType={transactionType}
                disco={disco}
                onClick={() => setShowReceipt(false)}
                powerUnit={value || ""}
                transactionPage
              />
            </div>
          ) : (
            <>
              <h3>Transactions</h3>
              <div className="filter-container">
                <label className="filter-container_label">Filter Status</label>
                <button
                  type="button"
                  className="filter-container_button"
                  onClick={handleButtonClick}
                >
                  {label} <Chevron />
                </button>
                {visible && <div className="overlay" />}
                {visible ? (
                  <Dropdown
                    overlay={menu}
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    trigger={["click"]}
                  >
                    <span></span>
                  </Dropdown>
                ) : (
                  ""
                )}
              </div>
              {filteredTransactions.length === 0 ? (
                <EmptyState />
              ) : (
                <>
                  {Object.entries(groupedTransactions)?.map(
                    ([created_at, transactions]) => (
                      <div className="transactions_card" key={created_at}>
                        <label>{formatDate(created_at)}</label>
                        {transactions.map((transaction, i) => (
                          <div
                            className={`transactions_card-items ${
                              i !== 0 ? "with-border-top" : ""
                            }`}
                            key={i}
                          >
                            <div className="transaction-label">
                              <span className="transaction-label-icon">
                                <img 
                                  src={transaction?.service?.image_url} 
                                  width={30} 
                                  alt = 'E' 
                                  style={{
                                    borderRadius: 50
                                  }}
                                ></img>
                              </span>
                              <span className="transaction-label-amount">
                                <p className="amount">
                                  {`₦ ${formatAmount(transaction.amount)}`}
                                </p>
                                <span>
                                  <p className="transaction-id">
                                    {transaction.tx_ref}
                                  </p>
                                  <p>.</p>
                                  <p className="payment-method">
                                    {transaction.paymentType}
                                  </p>
                                </span>
                              </span>
                            </div>
                            <div className="transaction-status">
                              <span
                                className={`status ${transaction.status.toLowerCase()}`}
                              >
                                {transaction.status}
                              </span>
                              <span
                                onClick={() => handleChevronClick(transaction)}
                              >
                                <Chevron />
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                  {filteredTransactions?.length > 6 && (
                    <SapaButton
                      label={
                        displayedTransactions >= filteredTransactions.length
                          ? "Load Less"
                          : "Load More Transactions"
                      }
                      buttonType="bordered"
                      customClass="loadmore-btn"
                      onClick={handleLoadMore}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(Transactions);
